import { Dispatch, Fragment, LegacyRef, SetStateAction, useContext, useRef, useState } from "react"
import { Card, FormGroup } from "react-bootstrap"
import { moneyFormatter } from "../utility/helpers"
import { LOANAPPLICTION_DATA_TYPES, LoanApplicationData, LoanApplicationReducerAction } from "../reducers/LoanApplication.reducer"
import { GuarantorLabelData, findGuarantor, updateSumRequest } from "../methods/loanGuarantee"
import { PageContext, PageDispacthContext } from "../providers/PageProvider"


export const GuarantorLabel = ({
    applicationData,
    formDispatch,
    setError
}:{
    applicationData: LoanApplicationData,
    formDispatch: Dispatch<LoanApplicationReducerAction>,
    setError: Dispatch<SetStateAction<string | null>>
})=>{
    const {currentUser} = useContext(PageContext)
    const globalDispactch = useContext(PageDispacthContext)
    const [guarantorLabel, setGuarantoLabel] = useState<GuarantorLabelData>()
    // internally accessible guarantor info
    const ref: LegacyRef<HTMLInputElement> = useRef(null)
    const {guarantorList, guarantors, guaranteeRequest} = applicationData.loanApplicationForm!

    return (
        <Fragment>
            <FormGroup className="mb-2 form-control bg-dark">
                <input ref={ref} title="Laon guarantor" type="number" min={100} className="form-control" 
                onChange={async (e)=>{
                    const guarantorData = await findGuarantor(guarantors, e.target.value, ref, currentUser!, globalDispactch, setError)
                    
                    if(guarantorData.guarantor){
                        setGuarantoLabel(guarantorData)
                        // add to list of guarantors
                        guarantorList.push(guarantorData.reference)
                        guarantors.push(guarantorData.guarantor)
                        formDispatch({type: LOANAPPLICTION_DATA_TYPES.SET_FORM_DATA, loanApplicationForm: 
                            {...applicationData.loanApplicationForm, guarantorList: [...guarantorList], guarantors: [...guarantors] }
                        } as LoanApplicationReducerAction)
                    }
                }
                    } placeholder="Enter guarantor's account number" name="capital" />
            </FormGroup>
            {
                (guarantorLabel && guarantorLabel.guarantor!==null ) &&
                <div>
                    <Card>
                        <Card.Body>
                            <div className="d-flex">
                                <div className="me-3 pt-4">
                                    <img src={guarantorLabel.photoUrl!} alt="guarantor" className="guarantor-img" />
                                </div>
                                <div className="pt-4">
                                    <h3>{guarantorLabel.guarantor?.displayName}</h3>
                                    <FormGroup>
                                        <small>Request:</small>
                                        <input onChange={(e)=>{
                                            const amount = parseInt(e.target.value)
                                            const canUpdate = updateSumRequest(
                                                amount,
                                                guaranteeRequest,
                                                guarantorLabel.availablefund
                                            )
                                            if(canUpdate && guarantorLabel.guarantor!==null){
                                                // update locally and globally
                                                guarantorLabel.guarantor.sumRequest = amount
                                                guarantors[guarantorLabel.index] = guarantorLabel.guarantor
                                                setGuarantoLabel({...guarantorLabel})
                                                formDispatch({type: LOANAPPLICTION_DATA_TYPES.SET_FORM_DATA, loanApplicationForm: 
                                                    {...applicationData.loanApplicationForm, guarantors: [...guarantors] }
                                                } as LoanApplicationReducerAction)
                                            } else if(guarantorLabel.guarantor!==null) {
                                                // update locally and globally
                                                guarantorLabel.guarantor.sumRequest = 0
                                                guarantors[guarantorLabel.index] = guarantorLabel.guarantor
                                                setGuarantoLabel({...guarantorLabel})
                                                formDispatch({type: LOANAPPLICTION_DATA_TYPES.SET_FORM_DATA, loanApplicationForm: 
                                                    {...applicationData.loanApplicationForm, guarantors: [...guarantors] }
                                                } as LoanApplicationReducerAction)
                                            }
                                        }} title="guarantee request" className="form-control" type="number" name="amount" />
                                    </FormGroup>
                                    <p>Available fund: <span className="text-success">&#x20A6;{moneyFormatter(guarantorLabel.availablefund)}</span></p>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
        </Fragment>
    )
}