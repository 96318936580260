
export const Footer = ()=> {
    const d = new Date()
    return(
            <footer id="footer" className="footer">
                <div className="copyright">
                    &copy; Copyright <strong><span>KISCMS</span></strong> {d.getFullYear()}. All Rights Reserved
                </div>
                
            </footer>  
    );
}