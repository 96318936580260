
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons"
import {  Dispatch, useContext, useEffect, useState } from "react"
import { Modal, FormGroup, FormLabel, ButtonGroup, Button } from "react-bootstrap"
import { DBMODELS, INFLOW_PURPOSE } from "../dataTypes/UTILITY.constants"
import { FinanceData } from "../dataTypes/financials"
import { PageContext, PageDispacthContext } from "../providers/PageProvider"
import { useInitializePayment } from "../configurations/paystack.config"
import { getInitialFinaceData } from "../configurations/utility.config"
import { toast } from "react-toastify"
import { Receipts } from "../models/Receipts.model"
import { Savings } from "../models/Savings.model"
import { Shares } from "../models/Shares.model"
import { LANDINGPAGE_DATA_TYPES, LandingPageData, LandingPageReducerAction } from "../reducers/LandingPage.reducer"
import { setPageIsBusy } from "../methods/utility"


export const BalanceModal = ({show, onHide, title, previousData, pageSetter }:{
    show: boolean, 
    onHide: ()=>void, 
    title: DBMODELS,
    previousData: LandingPageData,
    pageSetter: Dispatch<LandingPageReducerAction>
})=>{

    const {currentUser, message} = useContext(PageContext)
    const dispatch = useContext(PageDispacthContext)
    const [formData, setFormData] = useState<FinanceData>(getInitialFinaceData(title, currentUser!))

    // make payment on paystack
    const initializePayment = useInitializePayment(currentUser?.email!, (formData.amount*100)+15000, formData.transactionRef)

    useEffect(()=>{
        // set user reference
        setFormData(prev=>({...prev, 
            purpose: title===DBMODELS.savings?INFLOW_PURPOSE.SAVINGS:
            (title===DBMODELS.shares?INFLOW_PURPOSE.SHARES:INFLOW_PURPOSE.DIVIDEND), 
            memberReference: currentUser?.reference!}))
    }, [title, currentUser?.reference])
    
    return (
        <Modal
          centered
          show={show}
          onHide={onHide}
        >
          <Modal.Header closeButton >
            <Modal.Title className="text-success">
              {`Increase your ${DBMODELS[title]}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {message && <small className="text-center text-danger">{message.message}</small>}
            <FormGroup className="mb-2 mt-2">
                <FormLabel>
                    Amount:
                </FormLabel>
                <input type="number" className="form-control" title={`enter amount to save to ${DBMODELS[title]}`} onChange={(e)=>{
                    const amount = parseInt(e.target.value)
                    setFormData({...formData, amount: isNaN(amount)?0:amount})
                }} defaultValue={ isNaN(formData.amount)?0:formData.amount } name='amount' />
            </FormGroup> 
           
          </Modal.Body>
          <Modal.Footer>
                <ButtonGroup>
                    <Button disabled={formData.amount <= 0} onClick={()=>{
                       // make payment
                       initializePayment(()=>{
                        setPageIsBusy(dispatch, true)
                        const update = async()=>{
                            try {
                                const recModel = new Receipts()
                                const itemModel = title===DBMODELS.savings ? new Savings() : new Shares()
                                await Promise.all([
                                    await recModel.save(formData),
                                    await itemModel.incrementDecrement({
                                        dbReference: currentUser?.reference!,
                                        key: 'amount',
                                        isIncrement: true,
                                        incrementalValue: formData.amount
                                    })
                                ])
                                onHide()
                                setPageIsBusy(dispatch, false)
                                if(title===DBMODELS.savings){
                                    previousData.creditBalances.savings.amount+=formData.amount
                                } else {
                                    previousData.creditBalances.shares.amount+=formData.amount
                                }
                                // update current state
                                pageSetter({type: LANDINGPAGE_DATA_TYPES.SET_INITIAL_DATA, ...previousData})
                            } catch (_) {
                                onHide()
                                setPageIsBusy(dispatch, false)
                                toast("unable to update transaction contact admin!", {type: 'error'})
                            }
                        }
                        update()
                       }, ()=>{toast("Incomplete payment transaction!", {type: 'error'})})
                    }} className="btn-sm btn-success" >
                        <FontAwesomeIcon icon={
                            faMoneyBill 
                            } className="me-1" /> Make payment
                    </Button>
                </ButtonGroup>
          </Modal.Footer>
        </Modal>
    )
}