import {LegacyRef, useCallback, useContext, useEffect, useRef, useState } from 'react'
import '../styles/login.css'
import { Button, FormControl } from 'react-bootstrap'
import { PageDispacthContext} from '../providers/PageProvider'
import { authErrorChecker, checkCanLogin, doLogin } from '../methods/auth'
import { OTP } from './OTP.screen'
import { RecaptchaVerifier } from 'firebase/auth'
import { auth } from '../configurations/firebase.config'
import { MFAVerifier } from 'firebaseql'



export const Login = ()=>{

    const recapRef: LegacyRef<HTMLDivElement> = useRef(null)
    const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(null)
    const [recaptchaverifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>()

    const setCurrentData = useContext(PageDispacthContext)!
    const [email, setEmail] = useState<string>()
    const [password, setPassword] = useState<string>()
    const [validationError, setValidationError] = useState<string | null>(null)
    const [verifier, setVerifier] = useState<MFAVerifier | null>(null)
    const [elementId, setElementId] = useState<number| null>(null)
    

    const setRecaptcha = useCallback(()=>{
        // set recaptcher
        if(recapRef.current){
            try {
                const verifier = new RecaptchaVerifier(auth, recapRef.current.id, {
                    'size': 'normal',
                    'callback': async (response: string) => {
                        setRecaptchaResponse(response)
                    },
                    'expired-callback': () => {
                        setRecaptchaResponse(null)
                    }
                  });

                  if(!recaptchaverifier){
                    setRecaptchaVerifier(verifier)
                  }
            } catch (error) {
                console.log('recaptcha error: ', error)
            }

        }           
    }, [recaptchaverifier])

    // recaptcha
    useEffect(()=>{
        if(recaptchaverifier && elementId===null){
            recaptchaverifier.render().then(value=>setElementId(value))
        } 

        if(recaptchaverifier===undefined){
            setRecaptcha()
        }

        // dosendOTP()
    }, [setRecaptcha, recaptchaResponse, elementId])

    return (
        <section className="h-100 gradient-form main-container" >
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-xl-10">
                    <div className="card rounded-3 text-black">
                    <div className="row g-0">
                        <div className="col-lg-6">
                        <div className="card-body p-md-5 mx-md-4">

                            <div className="text-center">
                            <img src="./logo.png"
                               className='login-logo'  alt="logo" />
                            <h4 className="mt-1 mb-5 pb-1 text-success">We are one big family</h4>
                            </div>

                            <div>
                            <p>Please login to your account</p>
                            {validationError && <p className='text-center text-danger'>{validationError}</p>}
                            <div className="form-outline mb-2">
                                <FormControl 
                                onChange={(e)=>{
                                    const mail = e.target.value
                                        setEmail(mail)
                                }} 
                                type="email"   
                                name="email"
                                placeholder="email address" />
                                <label className="form-label text-success" >Email</label>
                            </div>

                            <div className="form-outline mb-2">
                                <FormControl onChange={(e)=>{
                                    if(authErrorChecker({item: e.target.value, identifier: 'password'})){
                                        setPassword(e.target.value)
                                    }
                                }} type="password" name="password" placeholder="Enter password"  />
                                <label className="form-label text-success" >Password</label>
                            </div>

                            <div className="text-center pt-1 mb-2 pb-1">
                                <Button  
                                    disabled={validationError!==null || email===undefined || password===undefined || recaptchaResponse===null} 
                                    id="sign-in-button" onClick={()=>{
                                        const error = checkCanLogin({email, password})
                                        if(error){
                                            setValidationError(error)
                                        } else {
                                            doLogin({
                                                email: email!,
                                                password: password!,
                                                recaptcha: recaptchaverifier!, 
                                                setCurrentData,
                                                setVerifyUser: setVerifier,
                                                setValidationError
                                            })
                                        }
                                }} className="btn-success form-control gradient-custom-2 mb-3" type="button">Log
                                in</Button>
                            </div>
                            <div className={`d-flex mt-2 justify-content-center ${recaptchaResponse ? "d-none":""}`}>
                            <div ref={recapRef} id='recaptcha-container'></div>
                        </div> 
                            </div>

                        </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                        <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                            <h4 className="mb-4">We are more than just a company</h4>
                            <p className="small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {verifier && <OTP show={verifier!==null} onHide={()=>{
                setVerifier(null)
                setRecaptchaResponse(null)
            }} verifier={verifier} />}
        </section>
    )
}