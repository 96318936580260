import { ConfirmationResult, RecaptchaVerifier } from "firebase/auth";
import { LegacyRef, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import OtpInput from 'react-otp-input';
import { auth } from "../configurations/firebase.config";
import { confirmOTPMessage, signIn } from "../methods/auth";
import { PageContext, PageDispacthContext } from "../providers/PageProvider";
import { toast } from "react-toastify";
import { setPageIsBusy } from "../methods/utility";
import { MFAVerifier } from "firebaseql";

const otpLength = 6

export const OTP = ({show, onHide, verifier}: {show: boolean, onHide:()=>void, verifier: MFAVerifier})=>{

    const [otp, setOtp] = useState('');
    const [isClicked, setIsCliced] = useState<boolean>(false)
    // const {currentUser} = useContext(PageContext)!
    const setCurrentData = useContext(PageDispacthContext)!

    return (

        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
            >
            <Modal.Body className="d-flex justify-content-center">
                <section>
                    <div>
                        <p className="text-center text-danger">Please enter your OTP below:</p>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={otpLength}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{minWidth: '60px', minHeight: '60px', fontSize: '30px', fontWeight: 'bold', }}
                        />
                        
                        <Button 
                            onClick={async ()=>{
                                setPageIsBusy(setCurrentData, true)
                                // confirm OTP
                                await confirmOTPMessage(`${otp}`, verifier, setCurrentData, onHide)
                            }}
                            disabled={otp.length !== otpLength || isClicked}
                            className="mt-3 btn-sm form-control btn-success">
                                Confirm
                        </Button>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    )
}