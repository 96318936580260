// import {  useContext, useEffect, useState } from "react"
import { Button, Card, FormGroup, FormLabel, Modal } from "react-bootstrap"
// import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCreditCard } from "@fortawesome/free-solid-svg-icons"
import { FinanceData, Loan, LoanApplicationCost, OtherConfigurationItem, REPAYMENT_SOURCE } from "../dataTypes/financials"
import { useCallback, useContext, useEffect, useState } from "react"
import { INFLOW_PURPOSE } from "../dataTypes/UTILITY.constants"
import { PageContext, PageDispacthContext } from "../providers/PageProvider"
import { moneyFormatter } from "../utility/helpers"
import { saveLoanRepayment, updateRepaymentValue } from "../methods/loan"
import { useInitializePayment } from "../configurations/paystack.config"
import { OtherConfigurations } from "../models/OtherConfigurations"
import { setPageIsBusy } from "../methods/utility"
import { toast } from "react-toastify"

export const LoanRepaymentModal = ({show, onHide, loan }:{show:boolean, onHide:()=>void, loan: Loan})=>{
    const d = new Date()

    const [loanApplicationCost, setLoanApplicationCost] = useState<LoanApplicationCost>({
        insurance: {
            amount: 0,
            date: d.getTime(),
            day: d.getDate(),
            month: d.getMonth() + 1,
            year: d.getFullYear()
        },
        application: {
            amount: loan.repayments.length > 0?0:500,
            date: d.getTime(),
            day: d.getDate(),
            month: d.getMonth() + 1,
            year: d.getFullYear()
        }
     })
    const repayments:number = loan.repayments.length > 0?loan.repayments.reduce((prev, curr)=>({...curr, amount: (+curr.amount+prev.amount)})).amount:0
    const loanFine = loan.fines.length > 0 ? loan.fines.reduce((prev, curr)=> ({...curr, cost: +curr.cost+prev.cost})).cost : 0
    const instalmnents = ((loan.capital * (1 + (loan.interestRate/100))/loan.instalments)) + loanFine
    const balance = (loan.capital * (1 + (loan.interestRate/100))) - repayments + loanFine
    const {currentUser} = useContext(PageContext)
    const {firstName, lastName, middleName} = currentUser!
    const [formData, setFormData] = useState<FinanceData>({
        date: d.getTime(),
        description: `Repayment for ${loan.category} loan by ${loan.applicantName}`,
        transactionRef: `REF_${Date.now()}_REPAYMNET_transfer`,
        source: REPAYMENT_SOURCE.TRANSFER,
        amount: Math.ceil(instalmnents > balance? 
            (+balance+(+loanApplicationCost.application.amount+loanApplicationCost.insurance.amount)): 
            (+instalmnents+(+loanApplicationCost.application.amount+loanApplicationCost.insurance.amount))),
        purpose: INFLOW_PURPOSE.LOAN_REPAYMENT,
        memberReference: loan.applicantRef,
        day: d.getDate(),
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        processor: `${firstName} ${middleName} ${lastName}`,
        memberName: loan.applicantName,
        processorRef: currentUser?.reference!
    } )
    const [validationError, setValidationError] = useState<string | null>(null)
    const pageDispatch = useContext(PageDispacthContext)

     // make payment on paystack
     const initializePayment = useInitializePayment(currentUser?.email!, ((
        formData?.amount! + 100
        )*100), formData?.transactionRef)
    
    const fetchOtherLoanCost = useCallback(async ()=>{
        // Get loan application cost
        if(loan.repayments.length === 0){
            // get cost of insurance
            const otherConfig = new OtherConfigurations()
            const data = await otherConfig.findAll()
            const config = data[0] as OtherConfigurationItem
            const otherCosts = +(loanApplicationCost.application.amount)+((config.insuranceCost/100) * loan.capital)

            if(config){
                setLoanApplicationCost({...loanApplicationCost, insurance: {
                    ...loanApplicationCost.insurance,
                    amount: (config.insuranceCost/100) * loan.capital
                }})
                
                setFormData({
                    ...formData,
                    amount: Math.ceil(instalmnents > balance? (+balance+otherCosts) : (+instalmnents+otherCosts) )
                })
            }
        }

    }, [balance, instalmnents, loan.capital, loan.repayments.length, loanApplicationCost.application.amount])

    useEffect(()=>{
        fetchOtherLoanCost()
    }, [fetchOtherLoanCost])
    
    return (
            <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered
            >
            <Modal.Header closeButton>
               Loan Repayment
            </Modal.Header>
            <Modal.Body>
            {validationError && <p className="text-center text-danger">{validationError}</p>}
                {
                    <>
                        <FormGroup className="">
                            <FormLabel>
                                Amount:
                            </FormLabel>
                            <input className="form-control" placeholder="Enter transaction amount" 
                                onChange={(e)=>{
                                    updateRepaymentValue(
                                        e, 
                                        setFormData,
                                        (loan.capital * (1 + (loan.interestRate/100))) + (+loanApplicationCost.insurance?.amount+loanApplicationCost.application?.amount),
                                        setValidationError
                                    )
                                }} 
                                defaultValue={formData?.amount} 
                                name="amount"
                                type="number"
                                step={0.1}
                             />
                        </FormGroup>

                        {
                            (loan.repayments.length === 0) &&
                            <div className="p-2">
                                <div className="d-flex justify-content-between mb-2">
                                    <div>Current instalment:</div>
                                    <div>{moneyFormatter(
                                        formData.amount - (+loanApplicationCost.insurance?.amount+loanApplicationCost.application?.amount), 2
                                    )}</div>
                                </div>
                                <Card.Header className="mb-1"><u>Application Cost</u></Card.Header>
                                <div className="d-flex justify-content-between mb-2">
                                    <div>Insurance:</div>
                                    <div>{moneyFormatter(loanApplicationCost.insurance?.amount??0, 2)}</div>
                                </div>
                                <div className="d-flex justify-content-between mb-2">
                                    <div>Application form:</div>
                                    <div>{moneyFormatter(loanApplicationCost.application?.amount??0, 2)}</div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="text-warning h5">Total:</div>
                                    <div className="h5 text-warning">{moneyFormatter(+loanApplicationCost.insurance?.amount+loanApplicationCost.application?.amount, 2)}</div>
                                </div>
                            </div>
                        }

                                <Card.Footer className="d-flex justify-content-between bg-info text-white">
                                    <div className="h4">Grand total:</div>
                                    <div className="h3">{moneyFormatter(formData.amount, 2)}</div>
                                </Card.Footer>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                {
                   
                    <Button 
                    onClick={async ()=>{
                       setPageIsBusy(pageDispatch, true)
                       initializePayment(()=>{
                            const savePayment = async ()=>{
                                await saveLoanRepayment(loan, loanApplicationCost, formData, onHide)
                                setPageIsBusy(pageDispatch, false)
                            }
                            savePayment()
                       }, ()=>{
                            setPageIsBusy(pageDispatch, false)
                            toast("Payment unsuccessful!", {type: 'error'})
                       })
                    }}
                    disabled={formData?.amount! <= 0 || formData?.transactionRef===''} 
                    variant="success" 
                    className="btn-sm"> <FontAwesomeIcon icon={faCreditCard} className="me-2" /> Pay
                </Button>
                }
            </Modal.Footer>
            </Modal>
    )
}