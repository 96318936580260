import { Card } from "react-bootstrap"
import { CustomTable, loanGuaranteeColumn } from "../../components/CustomTable.component"
import { useCallback, useContext, useEffect, useState } from "react"
import { PageContext } from "../../providers/PageProvider"
import { Loan } from "../../dataTypes/financials"
import { Loans } from "../../models/Loans.model"
import { LOANSTATUS } from "../../dataTypes/UTILITY.constants"
import { GuaranteeRequestModal } from "../../components/LoanGuaranteeRequestModal.component"

export const GuaranteeRequests = ()=> {
    const {currentUser} = useContext(PageContext)
    const [guaranteedLoans, setGuaranteedLoans] = useState<Loan[]>([])
    const [currentData, setCurrentData] = useState<Loan>()

    const findRequests = useCallback(async ()=>{
        const loanModel = new Loans()
        const data = await loanModel.findWhere({
            wh: [
                {
                    key: 'status',
                    operator: 'in',
                    value: [LOANSTATUS.PARTIALLY_GUARANTEED, LOANSTATUS.UNGUARANTEED]
                }
            ]
        })
        // filter items
        if(data.length > 0){
            const loans = data as Loan[]
            const filtered = loans.filter((loan)=>loan.guarantors.find(guarantor=>(guarantor.reference===currentUser?.reference && guarantor.approved===false)))
            setGuaranteedLoans(filtered.length > 0 ? filtered : [])
        }
    }, [currentUser?.reference])

    useEffect(()=>{
        // monitor loan applications
        findRequests()
    }, [findRequests])
    return (
        <Card>
            <Card.Header>Guarantee Requests</Card.Header>
            <Card.Body>
                <CustomTable
                    columns={loanGuaranteeColumn(currentUser?.reference!)}
                    data={guaranteedLoans}
                    rowKey='reference'
                    onClick={(row)=>{
                        setCurrentData(row as Loan)
                    }}
                />
            </Card.Body>
            {
                currentData && 
                <GuaranteeRequestModal 
                    show={currentData!==undefined}
                    onHide={()=>setCurrentData(undefined)}
                    loan={currentData}
                />
            }
        </Card>
    )

}