import { Dispatch, createContext, useReducer } from "react"
import { UserData } from "../dataTypes/user.types"
import { getSessionKey } from "firebaseql"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { CustomSpinner } from "../components/CustomSpinner.component";
import { Login } from "../screens/Login.screen";
import { MainWrapper } from "../screens/Main";
import { ResetPassword } from "../screens/ResetPassword";
import { MESSAGE_TYPE, generalMessage } from "../dataTypes/notificationMessages.types";

export type PageReducerAction = {
    type: REDUCER_TYPES,
    currentUser: UserData | null,
    isSignedIn: boolean,
    pageIsBusy: boolean,
    pageTitle: string,
    pageSubtitle: string,
    message: generalMessage | null
}

export type PageContent = {
    currentUser: UserData | null,
    isSignedIn: boolean,
    pageIsBusy: boolean,
    pageTitle: string,
    pageSubtitle: string,
    message: generalMessage | null
}

const initialState = {
    currentUser: null,
    isSignedIn: false,
    pageIsBusy: false,
    pageTitle: "Financial Overview",
    pageSubtitle: "Data Summary",
    message: null
}

// check if user session is on
const fbSession = getSessionKey()
const currentData = sessionStorage.getItem('currentData') 

export enum REDUCER_TYPES {
    SET_CURRENTUSER,
    PAGE_IS_BUSY,
    SET_PAGE_TITLE,
    SET_GLOBAL_MESSAGE
}

/**
 * Page data reducer
 * @param previousState 
 * @param action 
 * @returns 
 */
const reducer = (previousState: PageContent, action: PageReducerAction) : PageContent =>{

    let result = previousState

    switch (action.type) {
        
        case REDUCER_TYPES.SET_CURRENTUSER:
            result =   {
                ...previousState,
                currentUser: action.currentUser!,
                isSignedIn: action.isSignedIn
            };
            break;

        case REDUCER_TYPES.PAGE_IS_BUSY :
            result = {
                ...previousState,
                pageIsBusy: action.pageIsBusy
            };
            break;

        case REDUCER_TYPES.SET_PAGE_TITLE :
            result = {
                ...previousState,
                pageTitle: action.pageTitle,
                pageSubtitle: action.pageSubtitle
            };
            break;
        
        case REDUCER_TYPES.SET_GLOBAL_MESSAGE :
            result = {
                ...previousState,
                message: action.message
            };
            break;

        default:
            throw new Error("Invalid provider type: PageReducer")
    }

    sessionStorage.setItem("currentData", JSON.stringify(result))
    return result
}



export const PageContext = createContext<PageContent>(JSON.parse(currentData!) ?? initialState)
export const PageDispacthContext = createContext<Dispatch<PageReducerAction>>(()=>{})


export const PageProvider = ()=>{
    const [currentContext, setCurrentContext] = useReducer(reducer, (fbSession && currentData) ? JSON.parse(currentData!) as PageContent : initialState)
   const {pageIsBusy, currentUser, isSignedIn} = currentContext
    return (
        <PageContext.Provider  value={currentContext} >
            <PageDispacthContext.Provider value={setCurrentContext}>
                <ToastContainer />
                {pageIsBusy && <CustomSpinner />}
                {
                    (currentUser && isSignedIn===true) ?
                    (
                        currentUser.needResetPassword ?
                        <ResetPassword /> : <MainWrapper />
                    ): <Login />
                }
            </PageDispacthContext.Provider>
        </PageContext.Provider>
    )
}

/**
 * set global message 
 * @param dispatch 
 * @param message 
 * @param isError 
 */
export const setGlobalMessage = (dispatch:Dispatch<PageReducerAction>, message: string | null, isError?: boolean)=>{
    if(message===null){
        dispatch({type: REDUCER_TYPES.SET_GLOBAL_MESSAGE, message} as PageReducerAction)
    }else {
        dispatch({type: REDUCER_TYPES.SET_GLOBAL_MESSAGE, message: {
            message,
            type: isError? MESSAGE_TYPE.ERROR : MESSAGE_TYPE.SUCCESS
        }} as PageReducerAction)
    }
}