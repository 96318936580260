import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { PageNotFound } from "../404.screen";
import { PageWrapper } from "./Wrapper.screen";
import { Landing } from "./Landing.screen";
import { LoanApplication } from "./LoanApplication.screen";
import { MembershipWithdrawal } from "./MembershipWithdrawal.screen";
import { GuaranteeRequests } from "./GuaranteeRequests.screen";
import { Profile } from "./Profile.screen";

const router = createBrowserRouter([
    {
      path: "/",
      element: <PageWrapper />,
      errorElement: <PageNotFound />,
      children: [
        {
            path: '',
            element: <Landing />
        },
        {
            path: 'loan',
            element: <LoanApplication />
        },
        {
            path: 'withdrawal',
            element: <MembershipWithdrawal />
        },
        {
            path: 'requests',
            element: <GuaranteeRequests />
        },
        {
            path: 'complaints',
            element: <div>Yet to create</div>
        },
        {
            path: 'profile',
            element: <Profile />
        }
        
      ]
    },
  ]);

export const MainWrapper = ()=>{
    return (
        <RouterProvider router={router} />
    )
}