import '../styles/pageNotFound.css'

export const PageNotFound = ()=>{
    return (
        <>
            <div className="error">
                <div className="container-floud">
                    <div className="col-xs-12 ground-color text-center">
                        <p className='text-center text-success p-2 h1'>KISCMS</p>
                        <div className="container-error-404">
                            <div className="clip"><div className="shadow"><span className="digit thirdDigit">4</span></div></div>
                            <div className="clip"><div className="shadow"><span className="digit secondDigit">0</span></div></div>
                            <div className="clip"><div className="shadow"><span className="digit firstDigit">4</span></div></div>
                            <div className="msg">OH!<span className="triangle"></span></div>
                        </div>
                        <h2 className="h1">Sorry! Page not found</h2>
                    </div>
                </div>
                <div className='text-center' >
                    <a href='/' title='Home page'>Home page</a>
                </div>
            </div>
        </>
    )
}