import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { FinanceData, Loan, LoanApplicationCost } from "../dataTypes/financials";
import { toast } from "react-toastify";
import { moneyFormatter } from "../utility/helpers";
import { OUTFLOW_PURPOSE, LOANSTATUS } from "../dataTypes/UTILITY.constants";
import { LoanApplications } from "../models/LoanApplications.model";
import { LoanInsurances } from "../models/LoanInsurances.model";
import { Loans } from "../models/Loans.model";
import { Payments } from "../models/Payments.model";
import { Receipts } from "../models/Receipts.model";
import { Savings } from "../models/Savings.model";
import { dbItems } from "firebaseql";

/**
 * update loan instalment repayable
 * @param e 
 * @param setFormData 
 * @param maximumVal 
 */
export const updateRepaymentValue = (
    e: ChangeEvent<HTMLInputElement>,
    setFormData: Dispatch<SetStateAction<FinanceData>>,
    maximumVal: number,
    setValidationError: Dispatch<SetStateAction<string | null>>
) => {
    // check if amount is greater or equal to maximum amount
    const val = e.target.value
    if(parseInt(val) > maximumVal ){
        setValidationError(`Repayment cannot be greater than ${moneyFormatter(Math.ceil(maximumVal), 2)}`)
        setFormData(prev=>({...prev, amount: Math.ceil(parseInt(val))} as FinanceData))
    }else if(isNaN(parseInt(val)) || parseInt(val) <= 0){
        setValidationError("Please enter a valid amount")
    }else{
        setValidationError(null)
        setFormData(prev=>({...prev, amount: Math.ceil(parseInt(val))} as FinanceData))
    }
}

/**
 * save loan repayment to database after success
 * on paystack
 * @param loan 
 * @param applicationCost 
 * @param formData 
 */
export const saveLoanRepayment = async (
    loan: Loan,
    applicationCost: LoanApplicationCost,
    formData: FinanceData,
    onClose: ()=>void
) =>{
    // check loan fines
    const fine = loan.fines.length > 0 ? loan.fines.reduce((pre, curr)=>({...curr, cost: +curr.cost+pre.cost})).cost: 0
    // compute instalment interest to be shared by guarantors
    const instalmentInterest = Math.floor(formData.amount * (loan.interestRate/100))
    const otherCosts = loan.repayments.length > 0 ? 0: applicationCost.application.amount + applicationCost.insurance.amount
    formData.amount = formData.amount - otherCosts

    try {
        await Promise.all([
            // save registration cost and save insurance cost
            saveLoanAdminCharge(applicationCost, loan.reference!),
        
            // save to Guarantors account
            saveGuarantoBonus(
                loan, 
                instalmentInterest, 
                formData),
    
            // save loan repayment
            saveLoanReceipt(loan, formData, fine)
        ])
        onClose()
    } catch (error) {
        toast("Unable to complete transaction!, Contact your administrator", {type: "error"})
    }
}

/**
 * save 10% on loan interest to guaranto savings
 * @param loan 
 * @param guarantorInterest 
 * @param formData 
 * @param admin 
 */
const saveGuarantoBonus = async (loan: Loan, guarantorInterest: number, formData: FinanceData)=>{
    const d = new Date()
    const savModel = new Savings()
    if(loan.guarantorList.length > 0){
        // guarantor bonus is 10% of repayment less other cost of loan
        // add to payments
        const payModel = new Payments()
        const payData: FinanceData = {
            date: d.getTime(),
            description: `Guarantor interest on ${loan.category}`,
            transactionRef: `REF_${loan.reference}_GUARANTOR_INTEREST`,
            amount: guarantorInterest,
            purpose: OUTFLOW_PURPOSE.GUARANTOR_BONUS,
            memberName: loan.guarantorList.toString(),
            memberReference: loan.guarantorList.toString(),
            day: d.getDate(),
            year: d.getFullYear(),
            month: d.getMonth() + 1,
            source: formData.source,
            processor: loan.applicantName,
            processorRef: loan.applicantRef,
        }

        await payModel.save(payData)
        // reduce guarantors' liabilities
        // calculate total sum guaranteed
        const totalRequest = loan.guarantors.reduce((prev, curr)=>
        ({...curr, sumRequest: +curr.sumRequest+prev.sumRequest})).sumRequest

        for(let indx=0; indx < loan.guarantors.length; indx++){
            const {reference, sumRequest, requestOffset} = loan.guarantors[indx]
            // get proportion of guarantor
            const proportion = Math.floor((sumRequest/totalRequest) * guarantorInterest)
            if(sumRequest > requestOffset){
                // reduce guarantor's liability
                loan.guarantors[indx].requestOffset+=Math.floor((sumRequest/totalRequest) * formData.amount)
            }

            // increase guarantors' savings by guarantor credit
            await savModel.incrementDecrement({
                dbReference: reference,
                key: 'amount',
                isIncrement: true,
                incrementalValue: proportion
            })
        }
    }
}

/**
 * Save loan receipt
 * @param loan 
 * @param formData 
 * @param fines 
 */
const saveLoanReceipt = async (loan: Loan, formData: FinanceData, fines: number)=>{
    const receiptModel = new Receipts()
    const loanModel = new Loans()
    const id = await receiptModel.save(formData)
    // add repayment to loan
    try {
        if(id){
            // add repayment to loan
            formData.reference = id as string
            loan.repayments.push(formData)
            // check if the loan is fully paid
            const expectedAmount = Math.ceil(loan.capital * (1+(loan.interestRate/100))) + fines
            const totalReceipts = Math.floor(loan.repayments.length > 0 ?loan.repayments.reduce((pre, curr)=>({...curr, amount: +curr.amount+pre.amount})).amount : 0)
            // change loan status if fully paid
            if(totalReceipts >= expectedAmount){
                loan.status = LOANSTATUS.COMPLETED
            }
            // update loan data
            await loanModel.update({...loan, approvals: Object.fromEntries(loan.approvals)} as dbItems, loan.reference!)
        } else {
            // something went wrong
            toast("Incomplete process, Check connections!", {type: 'error'})
        }
    } catch (error) {
        toast("Incomplete process, Check connections!", {type: 'error'})
    }
}

/**
 * save loan administration cost
 * @param otherCost 
 * @param loanId 
 */
const saveLoanAdminCharge = async (otherCost: LoanApplicationCost, loanId: string)=>{
    try {
        // save application cost
        if(otherCost.application.amount >0 ){
            const appModel = new LoanApplications()
            // save application cost
            await appModel.save(otherCost.application, loanId)
            
        } 
        
        if (otherCost.insurance.amount > 0){
            const insModel = new LoanInsurances()
            // save insurance cost
            await insModel.save(otherCost.application, loanId)
        }
    } catch (error) {
        toast("Incomplete process, Check connections!", {type: 'error'})
    }
}