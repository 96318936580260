import { UserEmploymentInfo } from "../dataTypes/user.types"
import { moneyFormatter } from "../utility/helpers"


export const EmploymentInfoDisplay = ({info}:{info:UserEmploymentInfo})=>{
    return (
        <div>
            <div className="row">
                <div className="col-5">
                    <small className="text-grey">KIND number:</small>
                </div>
                <div className="col-7">
                    {info.KINDNumber}
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-5">
                    <small className="text-grey">Business unit:</small>
                </div>
                <div className="col-7">
                    {info.businessUnit}
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-5">
                    <small className="text-grey">Department:</small>
                </div>
                <div className="col-7">
                    {info.department}
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-5">
                    <small className="text-grey">Current salary:</small>
                </div>
                <div className="col-7">
                    N{moneyFormatter(info.currentSalary)}
                </div>
            </div>
        </div>
    )
}