import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { passwordTester } from "../utility/regex";
import { Users } from "../models/Users.model";
import { toast } from "react-toastify";
import { PageReducerAction } from "../providers/PageProvider";
import { signOut } from "./auth";
import { UserData } from "../dataTypes/user.types";
import { setPageIsBusy } from "./utility";
import { auth } from "../configurations/firebase.config";

export const updatePassword = (event: ChangeEvent<HTMLInputElement>, setter: Dispatch<SetStateAction<string | null>>, errorSetter: Dispatch<SetStateAction<string | null>>, password?: string)=>{
    const key = event.target.name
    const value = event.target.value

    if(key==='password'){
        if(passwordTester.test(value)){
            errorSetter(null)
            setter(value)
        }else{
            errorSetter("Password should be a minimum of 8 characters in length, and must contain atleast an Uppercase, a number and special character")
        }
    } else {
        if(password!==value){
            errorSetter("Passwords do not match!")
        } else {
            errorSetter(null)
            setter(value)
        }
    }
}

export const doPasswordReset = async (newPassword: string, pageSetter: Dispatch<PageReducerAction>, currentUser: UserData)=>{
    const model = new Users()
    setPageIsBusy(pageSetter, true)
    try {
        const reset = await model.resetPassword({auth, newPassword})
        if(reset){
            // update user info
            currentUser.needResetPassword = false
            await model.update(currentUser, currentUser.reference!)
            // sign in user
            toast("Password reset successful! Login with new password.", {type: 'success'})
            setPageIsBusy(pageSetter, false)
            signOut(pageSetter)
        } else{
            // something happened
            toast("Unable to reset, contact administrator!")
            setPageIsBusy(pageSetter, false)
            signOut(pageSetter)
        }
    } catch (error) {
        setPageIsBusy(pageSetter, false)
        toast("Unable to reset, check connections!")
    }
}