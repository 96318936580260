import { Fragment, useContext } from "react"
import {  faMarsAndVenus, faRing, faEarthAfrica, faMap, faMapLocationDot, faAt,  faPhone, faCalendar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PageContext } from "../providers/PageProvider"

export const BiodataDisplay = ()=>{
    const {currentUser} = useContext(PageContext)
    return (
        <Fragment>
             <div className="d-flex justify-content-center">
                    <div className="circular-profile">
                        <img src={currentUser?.photoUrl===''?'/assets/images/logo512.png': currentUser?.photoUrl} alt="Profile" className="circular-profile-img"/>
                    </div>
                </div>
                <div className="mt-2 text-center">
                    <h5 className="text-grey">{`${currentUser?.firstName} ${currentUser?.middleName} ${currentUser?.lastName}`}</h5>
                </div>
                <div>
                    
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <FontAwesomeIcon icon={faMarsAndVenus} className="me-2" /> {currentUser?.gender.toLocaleLowerCase()}
                        </div>
                        <div className="">
                            <FontAwesomeIcon icon={faCalendar} className="me-2" /> {currentUser?.dateOfBirth}
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faRing} className="me-2" /> {currentUser?.maritalStatus.toLocaleLowerCase()}
                        </div>
                    </div> 
                    
                    <div className="d-flex justify-content-between mt-3">
                        <div className="">
                            <FontAwesomeIcon icon={faEarthAfrica} className="me-2" /> {currentUser?.nationality}
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faMap} className="me-2" /> {currentUser?.stateOfOrigin}
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faMapLocationDot} className="me-2" /> {currentUser?.lgaOfOrigin}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mt-3">
                        
                        <div>
                            <FontAwesomeIcon icon={faPhone} className="me-2" /> {currentUser?.phoneNumbers.map((phone)=>phone+ ', ')}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mt-3">
                        <div className="">
                            <FontAwesomeIcon icon={faAt} className="me-2" /> {currentUser?.email}
                        </div>
                    </div>
                </div>
        </Fragment>
    )
}