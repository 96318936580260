import { Button, FormLabel } from 'react-bootstrap'
import '../styles/custom.css'
import { useContext, useState } from 'react'
import { doPasswordReset, updatePassword } from '../methods/resetpassword'
import { PageContext, PageDispacthContext } from '../providers/PageProvider'

export const ResetPassword = ()=>{

    const [password, setPassword] = useState<string | null>(null)
    const [resetPassword, setResetPassword] = useState<string | null>(null)
    const [error, setError] = useState<string | null>(null)
    const pageSetter = useContext(PageDispacthContext)
    const {currentUser} = useContext(PageContext)

    return (
        <section className="p-4 p-md-5 mt-5" >
            <div className="row d-flex justify-content-center mt-5">
                <div className="col-md-10 col-lg-8 col-xl-5">
                <div className="card rounded-3">
                    <div className="card-body p-4">
                        <div className='d-flex justify-content-center mb-3'>
                            <img src="./logo.png" className='login-logo'  alt="logo" />
                        </div>
                    <div className="text-center mb-4">
                        <h4 className='text-success'>Reset Password</h4>
                        <p className='text-info'>Reset your password to continue</p>
                    </div>
                    {
                        error && <p className='text-center text-danger'>{error}</p>
                    }

                        <div className="d-flex flex-row align-items-center mb-4 pb-1">
                        <div className="flex-fill mx-3">
                            <div className="form-outline">
                            <FormLabel>Password</FormLabel>
                            <input
                                type='password'
                                name="password"
                                title="user new password field"
                                className='form-control'
                                onChange={(e)=>{
                                    updatePassword(e, setPassword, setError)
                                }}
                            />
                            </div>
                        </div>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-4 pb-1">
                        <div className="flex-fill mx-3">
                            <div className="form-outline">
                            <FormLabel>Confirm Password</FormLabel>
                            <input
                                type='password'
                                name="resetPassword"
                                title='reset password field'
                                className='form-control'
                                onChange={(e)=>{
                                    updatePassword(e, setResetPassword, setError, password!)
                                }}
                            />
                            </div>
                        </div>
                        </div>

                        <Button 
                            onClick={()=>{
                                doPasswordReset(password!, pageSetter, currentUser!)
                            }}
                            disabled={
                                password===null || resetPassword===null || error!==null
                            }
                            className="btn btn-success btn-lg btn-block btn-sm">Reset
                        </Button>
                    </div>
                </div>
                </div>
            </div>
        </section>
    )
}