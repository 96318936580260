import { Button, ButtonGroup, FormLabel, Modal } from "react-bootstrap"
import { Loan } from "../dataTypes/financials"
import { useContext } from "react"
import { PageContext } from "../providers/PageProvider"
import { moneyFormatter } from "../utility/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCancel, faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import { approveLoanGuarantee, rejectLoanGuarantee } from "../methods/loanGuarantee"

export const GuaranteeRequestModal = ({
    show,
    onHide,
    loan
}: {
    show: boolean,
    onHide: ()=>void,
    loan: Loan
})=>{
    const {applicantName, guarantors, capital} = loan
    const {currentUser} = useContext(PageContext)
    const guarantor = guarantors.find(gu=>gu.reference===currentUser?.reference)
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            >
            <Modal.Header closeButton>
                {applicantName} Guarantee Request
            </Modal.Header>
            <Modal.Body>
                {
                    guarantor && 
                    <div>
                        <div className="row">
                            <div className="col-4">
                                <FormLabel>Loan: </FormLabel>
                            </div>
                            <div className="col">
                                N{moneyFormatter(capital, 2)}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <FormLabel>Request: </FormLabel>
                            </div>
                            <div className="col">
                                N{moneyFormatter(guarantor.sumRequest, 2)}
                            </div>
                        </div>

                        <ButtonGroup>
                            <Button onClick={async ()=> {
                                // approve loan guarantee
                                // 1. change loan status (partially or guaranteed)
                                // 2. change guarantee status and date (approved and today's date)
                               await approveLoanGuarantee(
                                currentUser?.reference!,
                                loan
                               )
                               onHide()

                            }} className="btn-sm btn-success">
                                <FontAwesomeIcon className="me-2" icon={faCircleCheck} />
                                Approve
                            </Button>

                            <Button onClick={ async ()=>{
                                // Reject loan guarantee
                                // 1. change loan status (partially or unguaranteed)
                                // 2. remove guarantor from loan (guarantors, guarantorList)
                                await rejectLoanGuarantee(
                                    currentUser?.reference!,
                                    loan
                                )
                            }}  className="btn-sm btn-danger">
                                <FontAwesomeIcon className="me-2" icon={faCancel} />
                                Reject
                            </Button>
                        </ButtonGroup>
                    </div>
                }
            </Modal.Body>
            
            </Modal>
    )
}