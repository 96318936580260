import { faDashboard, faBank,  faHandshake } from "@fortawesome/free-solid-svg-icons"
import { useContext, useEffect, useState } from "react"
import { LOANSTATUS, ListItem } from "../dataTypes/UTILITY.constants"
import { SidebarListItem } from "./SidebarListItem.component"
import { Loans } from "../models/Loans.model"
import { Loan } from "../dataTypes/financials"
import { PageContext } from "../providers/PageProvider"


export const SINGLELISTS: ListItem[] = [
    {
        label: 'Dashboard',
        path: '/',
        icon: faDashboard,
        pageTitle: 'Financial Overview',
        pageSubtitle: 'Data Summary'
    },
    {
        label: 'Loan Application',
        path: '/loan',
        icon: faBank,
        pageTitle: 'Loan Application',
        pageSubtitle: 'New Loan'
    },
    {
        label: 'Guarantee requests',
        path: '/requests',
        icon: faHandshake,
        pageTitle: 'Loan Guarantee',
        pageSubtitle: "Member's Guarantee requests"
    },
   

]
export const Sidebar = ()=>{
    const [loanCount, setLoanCount] = useState<number>()
    const {currentUser} = useContext(PageContext)

    useEffect(()=>{
        // monitor loan applications
        const loanModel = new Loans()
        loanModel.streamWhere([
            {
                key: 'status',
                operator: 'in',
                value: [LOANSTATUS.PARTIALLY_GUARANTEED, LOANSTATUS.UNGUARANTEED]
            }
        ], (data)=>{
            if(data.length > 0){
                const loans = data as Loan[]
                // filter
                const filtered = loans.filter((loan)=>loan.guarantors.find(guarantor=>(guarantor.reference===currentUser?.reference && guarantor.approved===false)))
                setLoanCount(filtered.length > 0 ? filtered.length : undefined)
            }
        }, ()=>{})
    }, [currentUser?.reference])
    
    return (
        <aside id="sidebar" className="sidebar primary-background">
            <div className="sidebar-nav" id="sidebar-nav">
                <div className="mb-5"></div>
                {
                    SINGLELISTS.map((listItem, key)=><SidebarListItem 
                    counter={listItem.label===SINGLELISTS[2].label?loanCount:undefined} 
                    item={listItem} key={key} 
                    pageSubtitle={listItem.pageSubtitle}
                    pageTitle={listItem.pageTitle}
                    />)
                }

            </div>

        </aside>
    )
}