import { generateRandomString } from "firebaseql";
import { DBMODELS, INFLOW_PURPOSE } from "../dataTypes/UTILITY.constants";
import { FinanceData, REPAYMENT_SOURCE } from "../dataTypes/financials";
import { UserData } from "../dataTypes/user.types";

/**
 * Initial data for making payment
 * @param item 
 * @param currentUser 
 * @param isPayment 
 * @returns 
 */
export const getInitialFinaceData = (item: DBMODELS, currentUser: UserData): FinanceData => {
    const d = new Date()
    return  {
        date: d.getTime(),
        description: "payment for "+DBMODELS[item],
        transactionRef: generateRandomString(7)+"_"+DBMODELS[item]+"_"+d.getTime(),
        amount: 0,
        purpose: item===DBMODELS.savings?INFLOW_PURPOSE.SAVINGS : (
            item===DBMODELS.shares? INFLOW_PURPOSE.SHARES : INFLOW_PURPOSE.LOAN_REPAYMENT
        ),
        memberName: `${currentUser?.firstName} ${currentUser?.lastName}`,
        day: d.getDate(),
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        memberReference: currentUser?.reference!,
        processor: `system`,
        processorRef: '',
        source: REPAYMENT_SOURCE.TRANSFER
    }
}