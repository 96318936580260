
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getToken, initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { toast } from "react-toastify";


// declare global {
//   // eslint-disable-next-line no-var
//   var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
// //   var recaptchaVerifier: RecaptchaVerifier
// }

export enum ENVIRONMENT {
  PRODUCTION,
  DEVELOPMENT
}

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBHLnJjFBQqex6iG2uM7rf7eQAD3vN95h0",
  authDomain: "kiscms-3e8ab.firebaseapp.com",
  projectId: "kiscms-3e8ab",
  storageBucket: "kiscms-3e8ab.appspot.com",
  messagingSenderId: "447220582227",
  appId: "1:447220582227:web:e8705ef7b9018eec30c3a6",
  measurementId: "G-M952KXR999"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestorDB = getFirestore(app);
export const storage = getStorage(app);


  // window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  try {
    // Recaptcha
    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider('6Lckm7cnAAAAACRGrZtrSJ0Y1uqE75e1UTTutd5Q'),
      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      isTokenAutoRefreshEnabled: true
    });

    getToken(appCheck, true)
  } catch (error) {
    toast("Check Internet connection", {type: 'info'})
  }

export const environment = window.location.hostname==='localhost' ? ENVIRONMENT.DEVELOPMENT : ENVIRONMENT.PRODUCTION