import { Dispatch, SetStateAction } from "react"
import { auth } from "../configurations/firebase.config"
import { Users } from "../models/Users.model"
import { emailTester } from "../utility/regex"
import { PageContent, PageReducerAction, REDUCER_TYPES } from "../providers/PageProvider"
import { ApplicationVerifier, User } from "firebase/auth"
import { toast } from "react-toastify"
import { UserData } from "../dataTypes/user.types"
import { setPageIsBusy } from "./utility"
import { MFAVerifier } from "firebaseql"

/**
 * 
 * @param param0 
 * @returns 
 */
export const checkCanLogin = ({email, password} : {email: string | undefined, password: string | undefined}): string | null =>{
    let canLogin: string | null = ''
    // check email
    if(!emailTester.test(email!)){
        canLogin = "Enter a valid email address"
    } else if(password===undefined || password===''){
        // check password
        canLogin = "Password field cannot be empty!"
    }  else {
        canLogin = null
    }
    return canLogin
}

export const authErrorChecker = ({item, identifier}: {item: string, identifier: 'email'|'password'}):boolean=>{
    if(identifier==='email'){
        return emailTester.test(item)
    }
    return item!==undefined
}

export const doLogin = async ({
    email,
    password,
    recaptcha,
    setCurrentData,
    setVerifyUser,
    setValidationError,
}: {
    email: string,
    password: string,
    recaptcha: ApplicationVerifier,
    setCurrentData: Dispatch<PageReducerAction>,
    setVerifyUser: Dispatch<SetStateAction<MFAVerifier | null>>,
    setValidationError: Dispatch<SetStateAction<string | null>>
}) => {
    const userModel = new Users()
    // page is busy
    try {
        setPageIsBusy(setCurrentData, true)
        const verifier = await userModel.loginWithMultiAuthFactor({email, password, auth, recaptcha, persist: true})
        if(verifier!==null){
            setVerifyUser(verifier)
        } else {
            setValidationError("Invalid Login Credentials")
            setPageIsBusy(setCurrentData, false)
        }
    } catch (e) {
        console.log("login error: ", e)
        setValidationError("Invalid Login Credentials! Account not recognized.")
        setPageIsBusy(setCurrentData, false)
    }
}

/**
 * sign in user after OTP confirmation
 * @param user 
 * @param setCurrentData 
 */
const completeLogin = async (user: User, setCurrentData: Dispatch<PageReducerAction>)=>{

    try {
        const userModel = new Users()
        const userData = await userModel.find(user.uid)
        if(userData){
            // set user in session
            signIn(setCurrentData, userData as UserData, true, false)
        } else {
            toast("No user document, Contact Administrator!", {type: 'error'})
        }
        setPageIsBusy(setCurrentData, false)
    } catch (error) {
        toast("Something went wrong, try again later", {type: 'error'})
    }
}


/**
 * confirm OTP
 * @param code 
 * @param verifier 
 * @returns 
 */
export const confirmOTPMessage = async (code: string, verifier: MFAVerifier, setter: Dispatch<PageReducerAction>, onError: ()=>void) =>{
    try {
        const userModel = new Users()
        const user =  await userModel.confirmOTP(verifier, code)
        if(user){
            completeLogin(user, setter)
        }
    } catch (_) {
       onError()
    }
}

export const signIn = (dispatch: Dispatch<PageReducerAction>, user?: UserData, signedIn?: boolean, pageIsBusy?: boolean) =>{
    // set user in session
    const data: PageContent = {
        currentUser: user!,
        isSignedIn: signedIn ?? false,
        pageIsBusy: pageIsBusy ?? false,
        pageSubtitle: "Data Summary",
        pageTitle: "Financial Overview",
        message: null
    }
    dispatch({type: REDUCER_TYPES.SET_CURRENTUSER, ...data})
}


/**
 * Logout user
 * @param dispatch 
 */
export const signOut = async (dispatch: Dispatch<PageReducerAction>)=>{
    try {
        const userModel = new Users()
        await userModel.logout({auth})
        // reload page
        dispatch({type: REDUCER_TYPES.SET_CURRENTUSER, currentUser: null, isSignedIn: false, pageIsBusy: false} as PageReducerAction)
         // clear session storage
         sessionStorage.clear()
    } catch (_) {
        toast("unable to reach the server, Check connections!", {type: 'error'})
    }
}
