
import { LOANSTATUS } from "../dataTypes/UTILITY.constants"
import { AccountBalance, Balances, FinanceData, Loan } from "../dataTypes/financials"
import { Dividends } from "../models/Dividends.model"
import { Loans } from "../models/Loans.model"
import { Payments } from "../models/Payments.model"
import { Receipts } from "../models/Receipts.model"
import { Savings } from "../models/Savings.model"
import { Shares } from "../models/Shares.model"

export type LandingPageData = {
    creditBalances: Balances,
    activeLoans: Loan[],
    currentLoanIndex: number,
    guaranteedLoans: Loan[],
    transactionHistory: FinanceData[]
}

export type LandingPageReducerAction = {
    type: LANDINGPAGE_DATA_TYPES,
    creditBalances: Balances,
    activeLoans: Loan[],
    currentLoanIndex: number,
    guaranteedLoans: Loan[],
    transactionHistory: FinanceData[]
}

export enum LANDINGPAGE_DATA_TYPES {
    SET_CURRENT_LOAN,
    SET_INITIAL_DATA,
}

const initialAccountBalance: AccountBalance = {
    amount: 0,
    name: ''
} as AccountBalance

export const landingPageInitialData: LandingPageData = {
    creditBalances: {
        unclaimedDividend: initialAccountBalance,
        savings: initialAccountBalance,
        shares: initialAccountBalance
    },
    activeLoans: [],
    currentLoanIndex: 0,
    guaranteedLoans: [],
    transactionHistory: []
}


export const landingPageReducer = (state: LandingPageData, action: LandingPageReducerAction): LandingPageData => {

    switch (action.type) {
        case LANDINGPAGE_DATA_TYPES.SET_INITIAL_DATA:
            return {
                ...state,
                creditBalances: action.creditBalances,
                activeLoans: action.activeLoans,
                guaranteedLoans: action.guaranteedLoans,
                transactionHistory: action.transactionHistory
            };

        case LANDINGPAGE_DATA_TYPES.SET_CURRENT_LOAN:
                return {...state, currentLoanIndex: action.currentLoanIndex};

        default:
            return state;
    }
}

export const fetchLandingPageInitialData = async (uid: string): Promise<LandingPageData> =>{
    
    try {
        const [
            creditBalances, 
            activeLoans, 
            guaranteedLoans, 
            transactionHistory
        ] = await Promise.all([
            fetchCreditBalances(uid),
            fetchActiveLoans(uid),
            fetchGuaranteedLoans(uid),
            fetchTransactionHistory(uid)
        ])
        return {creditBalances, activeLoans, guaranteedLoans, transactionHistory, currentLoanIndex: 0} 
    } catch (error) {
        console.log(error)
        return landingPageInitialData
    }
}

/**
 * fetch user's savings, dividends and shares
 * @param uid 
 * @returns 
 */
const fetchCreditBalances = async (uid: string): Promise<Balances> =>{
    const result = {
        unclaimedDividend: initialAccountBalance,
        savings: initialAccountBalance,
        shares: initialAccountBalance
    }
    // initialize all models
    const models = [new Dividends(), new Savings(), new Shares()]

    try {
        // fetch and set data from each model
        for (let i = 0; i< models.length; i++){
            const data = await models[i].find(uid) as AccountBalance
            if(data){
                switch (i) {
                    case 0:
                        result.unclaimedDividend = data
                        break;
                    case 1:
                        result.savings = data
                        break;
                    default:
                        result.shares = data
                        break;
                }
            }
        }
    } catch (_) {
        
    }

    return result
}

/**
 * Get currently active loans of member
 * @param uid 
 * @returns 
 */
const fetchActiveLoans = async (uid: string): Promise<Loan[]> =>{
    try {
        const inactiveStatus = [LOANSTATUS.CANCELLED, LOANSTATUS.COMPLETED]
        const model = new Loans()
        const data = await model.findWhere({
            wh: [
                {
                    key: 'status',
                    operator: 'not-in',
                    value: inactiveStatus
                },
                {
                    key: 'applicantRef',
                    operator: '==',
                    value: uid
                }
            ]
        }) as Loan[]
        return data
    } catch (_) {
        return []
    }
}

/**
 * Get currently active loans of member
 * @param uid 
 * @returns 
 */
const fetchGuaranteedLoans = async (uid: string): Promise<Loan[]> =>{
    try {
        const inactiveStatus = [LOANSTATUS.CANCELLED, LOANSTATUS.COMPLETED]
        const model = new Loans()
        const data =  await model.findWhere({
            wh: [
                {
                    key: 'guarantorList',
                    operator: 'array-contains',
                    value: uid
                },
                {
                    key: 'status',
                    operator: 'not-in',
                    value: inactiveStatus
                }
            ]
        }) as Loan[]
        return data
    } catch (_) {
        return []
    }
}

/**
 * get all receipts and payments
 * @param uid 
 * @returns 
 */
const fetchTransactionHistory = async (uid: string): Promise<FinanceData[]> => {

    const result: FinanceData[] = []
    const models = [new Payments(), new Receipts()]
    for(let model of models ){
        const data =  await model.findWhere({
            wh: [
                {
                    key: 'memberReference',
                    operator: '==',
                    value: uid
                },
            ],
            order: 'date',
            lim: 10
        }) as FinanceData[]
        result.push(...data)
    }
    // sort data by date
    result.sort((first, next)=>next.date - first.date)
    return result
}

