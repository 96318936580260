import { faFileLines } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useCallback, useContext, useEffect, useReducer } from "react"
import { Card, Button } from "react-bootstrap"
import { DBMODELS } from "../../dataTypes/UTILITY.constants"
import { LOAN_APPROVERS } from "../../dataTypes/financials"
import { Editor } from "../../dataTypes/user.types"
import { EditableCard } from "../../components/EditableCard.component"
import { CrediBalance } from "../../components/CreditBalance.component"
import { CustomTable, financialcolumns, loanGuaranteeColumn, loanRepaymentsColumn } from "../../components/CustomTable.component"
import { useNavigate } from "react-router-dom"
import { setPageIsBusy, setPageTitle } from "../../methods/utility"
import { PageContext, PageDispacthContext } from "../../providers/PageProvider"
import { LANDINGPAGE_DATA_TYPES, LandingPageReducerAction, fetchLandingPageInitialData, landingPageInitialData, landingPageReducer } from "../../reducers/LandingPage.reducer"
import { LoanCard } from "../../components/LoanCard.component"
import { ENVIRONMENT, environment } from "../../configurations/firebase.config"
import { toast } from "react-toastify"



export const Landing = ()=>{
    const navigate = useNavigate()
    const dispatch = useContext(PageDispacthContext)
    const {currentUser} = useContext(PageContext)
    const [pageData, pageDispatch] = useReducer(landingPageReducer, landingPageInitialData)

    const fetchPageData = useCallback(async ()=>{
        if(currentUser?.reference!){
            try {
                setPageIsBusy(dispatch, true)
                const data = await fetchLandingPageInitialData(currentUser.reference)
                pageDispatch({type: LANDINGPAGE_DATA_TYPES.SET_INITIAL_DATA, ...data})
                setPageIsBusy(dispatch, false)
            } catch (_) {
            }
        }
    }, [dispatch, currentUser])

    useEffect(()=>{
        fetchPageData()
    }, [fetchPageData])


    const {creditBalances, activeLoans, guaranteedLoans, currentLoanIndex, transactionHistory } = pageData

    return (
        <>
            <section className="row">
                <div className="col-lg-4 col-md-4">
                    <Card>
                        <Card.Header className="text-success">Balances</Card.Header>
                        <Card.Body className="pt-5">
                            <EditableCard pageTitle="Unclaimed Dividend" cardBody={ 
                                (creditBalances && creditBalances?.unclaimedDividend?.amount) &&
                                <CrediBalance amount={creditBalances.unclaimedDividend.amount} title={DBMODELS.dividend} isCalculated={true} />
                            } isEdit={false}  />
                            <EditableCard pageTitle="Shares" cardBody={ 
                                <CrediBalance amount={creditBalances?.shares.amount??0} title={DBMODELS.shares} pageSetter={pageDispatch} previousData={pageData}  />
                            }  isEdit={false}  />
                            <EditableCard pageTitle="Savings" cardBody={
                                <CrediBalance amount={creditBalances?.savings.amount ?? 0} title={DBMODELS.savings} pageSetter={pageDispatch} previousData={pageData} />
                            } isEdit={false}   />
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-lg-8 col-md-8">
                {/* className="d-flex mb-2 justify-content-between align-items-center bg-info pe-5" */}
                    <Card>
                        <Card.Header className="text-success me-5 d-flex justify-content-between">
                            Active Loans
                            <Fragment>
                                <Button 
                                    onClick={()=>{
                                        if(environment===ENVIRONMENT.PRODUCTION){
                                            toast("feature is currently disabled!", {type: 'info'})
                                        } else {
                                            setPageTitle(dispatch, "Loan Application", "New loan")
                                            navigate('/loan', {state: {
                                                existingLoans: pageData.activeLoans, 
                                                balances: pageData.creditBalances
                                            }})
                                        }
                                    }}
                                    title="New Loan" className="text-white btn-sm btn-success icon-btn" >
                                    <FontAwesomeIcon className="me-2" icon={faFileLines} /> 
                                </Button>
                            </Fragment>
                        </Card.Header>
                        <Card.Body>
                            <div className="row mt-4">
                                {
                                    activeLoans.length > 0 && 
                                    activeLoans.map((loan, index)=>{
                                        loan.approvals = new Map(Object.entries(loan.approvals)) as Map<LOAN_APPROVERS, Editor>
                                        return <LoanCard 
                                            key={index} 
                                            loan={loan} 
                                            isCurrent={index===currentLoanIndex} 
                                            applicant={currentUser!}
                                            className={['btn']} 
                                            onClick={()=>pageDispatch({type: LANDINGPAGE_DATA_TYPES.SET_CURRENT_LOAN } as LandingPageReducerAction)} 
                                        />
                                    })
                                }
                            </div>
                            { 
                                currentLoanIndex!==null && 
                                <Card>
                                    <Card.Header>Repayment schedule</Card.Header>
                                    <CustomTable
                                        columns={loanRepaymentsColumn}
                                        data={activeLoans[currentLoanIndex]?.repayments!}
                                        rowKey='reference'
                                    />
                                </Card>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </section>
            <Card>
                <Card.Header>Guaranteed Loans</Card.Header>
                <Card.Body>
                    <CustomTable
                        columns={loanGuaranteeColumn(currentUser?.reference!)}
                        data={guaranteedLoans}
                        rowKey='reference'
                    />
                </Card.Body>
            </Card>

            <Card>
                <Card.Header>Transaction History</Card.Header>
                <Card.Body>
                    <CustomTable
                        columns={financialcolumns}
                        data={transactionHistory}
                        rowKey='reference'
                    />
                </Card.Body>
            </Card>
        </>
    )
}