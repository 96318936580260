import { Dispatch, RefObject, SetStateAction } from "react";
import { LoanGuarantorData, UserData } from "../dataTypes/user.types";
import { toast } from "react-toastify";
import { Users } from "../models/Users.model";
import { LOANSTATUS, MEMBERSHIP_STATUS } from "../dataTypes/UTILITY.constants";
import { AccountBalance, Loan } from "../dataTypes/financials";
import { Loans } from "../models/Loans.model";
import { Savings } from "../models/Savings.model";
import { PageReducerAction } from "../providers/PageProvider";
import { setPageIsBusy } from "./utility";
import { dbItems } from "firebaseql";

export type GuarantorLabelData = {
    guarantor: LoanGuarantorData | null,
    photoUrl: string | null,
    availablefund: number,
    index: number,
    reference: string
}

export const findGuarantor = async (
    guarantors: LoanGuarantorData[],
    accountNumber: string,
    ref: RefObject<HTMLInputElement>,
    currentUser: UserData,
    globalDispatch: Dispatch<PageReducerAction>,
    setError: Dispatch<SetStateAction<string | null>>

): Promise<GuarantorLabelData> =>{
    const result: GuarantorLabelData = {
        guarantor: null,
        photoUrl: null,
        availablefund: 0,
        index: guarantors.length,
        reference: ''
    }
    const regNum = parseInt(accountNumber)
    // validate account number
    if(!isNaN(regNum) && accountNumber.length===10){
        setError(null)
        if(ref.current!){
            ref.current.disabled = true
        }
        // prevent multiple guaranteeing
        if(guarantors.find(guarantor=>guarantor.accountNumber===`${regNum}`)){
            toast("Member is already a guarantor", {type: 'error'})
            if(ref.current){
                ref.current.disabled = false
            }
        
            // prevent self guaranteeing
        } else if(regNum===parseInt(currentUser.accountNumber)){
            toast("Self guaranteeing not allowed!", {type: 'error'})
            if(ref.current){
                ref.current.disabled = false
            }
        } else{
            setPageIsBusy(globalDispatch, true)
            // search for user by account number and active status
            const guarantor = await fetchGuarantor(regNum.toString())
            // guarantor's data
            if(guarantor){
                const {firstName, lastName, reference, photoUrl, accountNumber} = guarantor
                result.guarantor = {
                    displayName: `${firstName} ${lastName}`,
                    reference: reference!,
                    sumRequest: 0,
                    approved: false,
                    accountNumber,
                    requestOffset: 0
                }
                result.reference = reference!
                // get guarantor's savings
                // get guarantor's loans
                const loansAndSavings = await fetchGuaranteedLoansAndSavings(reference!)
                if(loansAndSavings){
                    const [loans, savings] = loansAndSavings
                    const liability = computeGuaranteeLiability(reference!, loans)
                    if(savings){
                        const s = savings as AccountBalance
                        result.availablefund = s.amount - liability
                    }
                    result.photoUrl = photoUrl
                } 
                // release form input
                if(ref.current){
                    ref.current.disabled = false
                }
                
            } else {
                if(ref.current){
                    ref.current.disabled = false
                }
                toast("Member does not exist!", {type: 'error'})
            }
            setPageIsBusy(globalDispatch, false)
        }
    }else{
        if(ref.current){
            ref.current.disabled = false
        }
        setError("Enter a valid account number")
    }
    return result
}

/**
 * fetch guarantor data from db
 * @param regNum 
 * @returns 
 */
const fetchGuarantor = async (regNum: string): Promise<UserData | null>=>{
    try {
        const userModel = new Users()
    const data = await userModel.findWhere({
        wh: [
            {
                key: 'accountNumber',
                operator: "==",
                value: regNum
            },
            {
                key: 'status',
                operator: "==",
                value: MEMBERSHIP_STATUS.ACTIVE
            }
        ]})
    if(data.length > 0) {
        return data[0] as UserData
    } else {
        return null
    }
    } catch (_) {
        return null
    }
}

/**
 * fetch savings and all guaranteed loans of guarantor
 * @param reference 
 * @returns 
 */
const fetchGuaranteedLoansAndSavings = async (reference: string): Promise<[Loan[], AccountBalance | boolean] | null> => {
    const loanModel = new Loans()
    const savingsModel = new Savings()
    try {
        return await Promise.all([
            await loanModel.findWhere({
                wh: [
                {
                    key: 'gurantorList',
                    operator: "array-contains",
                    value: reference
                },
                {
                    key: 'status',
                    operator: "in",
                    value: [LOANSTATUS.ACTIVE, LOANSTATUS.APPROVED, LOANSTATUS.BAD, LOANSTATUS.DOUBTFUL, 
                        LOANSTATUS.GUARANTEED, LOANSTATUS.NON_PERFORMING, LOANSTATUS.PARTIALLY_GUARANTEED,
                        LOANSTATUS.UNGUARANTEED]
                }
                ],}) as Loan[],
            await savingsModel.find(reference) as AccountBalance | boolean
        ])
    } catch (error) {
        toast("Check connection", {type: 'info'})
        return null
    }
}

/**
 * calculate guarantor's liability of loans guaranteed
 * @param reference 
 * @param loans 
 * @returns 
 */
const computeGuaranteeLiability = (reference: string, loans: Loan[]): number=>{
    let result = 0

    loans.forEach(loan=>{
        const data = loan.guarantors.find(guarantor=>guarantor.reference===reference)
        if(data && data.requestOffset < data.sumRequest){
            result += data.sumRequest - data.requestOffset   
        }
    })

    return result
}

/**
 * validate sum request before update
 * @param amount 
 * @param request 
 * @param availableFund 
 * @returns 
 */
export const updateSumRequest = (amount: number, request: number, availableFund: number): boolean =>{
    let canUpate = false
    if(isNaN(amount)){
        toast("Enter a valid amount as request", {type: 'error'})
    }else if (amount > availableFund){
        toast("Amount is beyond guarantor's capacity", {type: 'warning'})
    } else if(amount > request){
        toast("Amount is beyond request", {type: 'info'})
    } else {
        canUpate = true
    }
    return canUpate
}

export const computeUnguaranteedSum = (guarantors: LoanGuarantorData[], request: number): number =>{
    const totalGuaranteed = guarantors.length > 0 ? guarantors.reduce((previous, current)=>(
        {...current, sumRequest: +current.sumRequest+previous.sumRequest})).sumRequest : 0
    return Math.ceil(request - totalGuaranteed)
}

/**
 * do apply for loan
 * @param form 
 */
export const saveLoanForm = async (form: Loan)=>{
    try {
        const model = new Loans()
        await model.save({...form, approvals: {}} as dbItems)
        toast("Loan application successful!", {type: 'success'})
    } catch (error) {
        toast("unable to complete application, Check connections!", {type: 'error'})
    }
}

/**
 * approve loan guarantee request
 * @param uid 
 * @param loan 
 * @returns 
 */
export const approveLoanGuarantee = async (uid: string, loan: Loan): Promise<boolean> =>{
    try {
        const guarantors = loan.guarantors
        const unguaranteed = guarantors.find(guarantor=>(guarantor.reference!==uid && guarantor.approved===false))
        const currentIndex = guarantors.indexOf(guarantors.find(guarantor=>guarantor.reference===uid)!)
        const currentGuarantor = loan.guarantors[currentIndex]
        // update loan status
        loan.status = unguaranteed ? LOANSTATUS.PARTIALLY_GUARANTEED : LOANSTATUS.GUARANTEED
        // change guarantor status
        currentGuarantor.approved = true
        currentGuarantor.approvalDate = new Date().getTime()
        loan.guarantors[currentIndex] = currentGuarantor
        const id = loan.reference
        delete loan.reference
        // update loan data
        const model = new Loans()
        await model.update(loan, id!)
        return true
    } catch (_) {
        toast("Unable to guarantee loan, Try later!", {type: 'error'})
        return false
    }
}

/**
 * reject loan guarantee request
 * @param uid 
 * @param loan 
 * @returns 
 */
export const rejectLoanGuarantee = async (uid: string, loan: Loan): Promise<boolean> =>{
    try {
        const guarantors = loan.guarantors
        const guaranteed = guarantors.find(guarantor=>(guarantor.reference!==uid && guarantor.approved===true))
        const currentIndex = guarantors.indexOf(guarantors.find(guarantor=>guarantor.reference===uid)!)
        // update loan status
        loan.status = guaranteed ? LOANSTATUS.PARTIALLY_GUARANTEED : LOANSTATUS.UNGUARANTEED
        // change guarantor status
        loan.guarantors.splice(currentIndex, 1)
        loan.guarantorList.splice(currentIndex, 1)
        const id = loan.reference
        delete loan.reference
        // update loan data
        const model = new Loans()
        await model.update(loan, id!)
        return true
    } catch (_) {
        toast("Unable to delete guarantee request, Try later!", {type: 'error'})
        return false
    }
}