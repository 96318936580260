import { faUserXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ButtonGroup, Button } from "react-bootstrap"
import { EditableCard } from "../../components/EditableCard.component"
import { BiodataDisplay } from "../../components/BiodataDisplay.component"
import { useContext } from "react"
import { PageContext } from "../../providers/PageProvider"
import { CooperativeInfoDisplay } from "../../components/CooperativeInfoDisplay.component"
import { NextOfKinDisplay } from "../../components/NextOfKinDisplay.component"
import { ContactDisplay } from "../../components/ContactDisplay.component"
import { EmploymentInfoDisplay } from "../../components/EmploymentInfoDisplay.component"

export const Profile = ()=>{
    const {currentUser} = useContext(PageContext)


    return (
        <section className="row">
            <div className="col-lg- col-md-4">
            <EditableCard isEdit={false}  pageTitle="Bio-Data" cardBody={<BiodataDisplay />}  />
            <ButtonGroup className="btn-shadow">
                <Button  onClick={()=>{
                    if(window.confirm("Action is irreversible! Are you sure?")){
                        // setShowWithdrawal(true)
                    }
                }} variant="danger" className="btn-sm">
                    <FontAwesomeIcon icon={faUserXmark} /> Membership Withdrawal
                </Button>
            </ButtonGroup>
            
            </div>
            <div className="col-lg-8 col-md-8">
                <div className="row">
                    <div className="col-lg-6">
                        <EditableCard isEdit={false} pageTitle="Cooperative Info" cardBody={<CooperativeInfoDisplay info={currentUser?.KISCMSInfo!} accountNumber={currentUser?.accountNumber!} />}   />
                    </div>
                    <div className="col-lg-6">
                        <EditableCard isEdit={false} pageTitle="Next of kin" cardBody={<NextOfKinDisplay nextOfKin={currentUser?.nextOfKin!} />} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <EditableCard isEdit={false}  pageTitle="Contact Info" cardBody={<ContactDisplay address={currentUser?.contactAddress!} />}  />
                    </div>
                    <div className="col-lg-6">
                        <EditableCard isEdit={false}  pageTitle="Employment Info" cardBody={<EmploymentInfoDisplay info={currentUser?.employmentDetail!} />}  />
                    </div>
                </div>
            </div>
        </section>
    )
}