import { faCreditCard } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap"
import { useState } from "react"
import { Loan } from "../dataTypes/financials"
import { UserData } from "../dataTypes/user.types"
import { EditableCard } from "./EditableCard.component"
import { LOANSTATUS } from "../dataTypes/UTILITY.constants"
import { moneyFormatter } from "../utility/helpers"
import { LoanRepaymentModal } from "./LoanRepaymentModal.component"


export const LoanCard = ({loan, isCurrent, className, onClick}: {loan: Loan, isCurrent: boolean, className?:string[], onClick: ()=>void, applicant: UserData})=>{
    const {capital, repayments, instalments, interestRate, startDate, status, applicationDate, fines} = loan
    const loanFine = fines.length > 0 ? fines.reduce((prev, curr)=> ({...curr, cost: +curr.cost+prev.cost})).cost : 0
    const monthlyInstalments = (capital * (1 + (interestRate/100)))/instalments
    const totalRepayments = repayments.length > 0?repayments.reduce((prev, curr)=>({...curr, amount: curr.amount+prev.amount})).amount:0
    const loanBalance = (capital * (1 + (interestRate/100))) - (totalRepayments ?? 0) + loanFine
    const [openRepaymentModal, setRepaymentModal] = useState<boolean>(false)
    
    return (
        <div className={`col-lg-6 col-md-6 ${className?.toString()}`} onClick={()=>{onClick()}}>
            <EditableCard isCurrent={isCurrent} pageTitle={loan.category} titleBadge={
                <Button className="btn-sm btn-light" >
                    {status}
                </Button>
            } cardBody={<>
            <div className="d-flex justify-content-between text-light">
                <div className="d-flex flex-column justify-content-between">
                    <div className="loan-instalments-card d-flex flex-column justify-content-center align-items-center">
                        <div className="display-3 pt-2 text-warning">{Math.ceil(loanBalance/monthlyInstalments)}</div>
                        <div className="bg-dark form-control text-white instalment-text">Instalments</div>
                    </div>
                </div>
                <div className="text-center">
                    <h3><u>&#x20A6;{moneyFormatter(capital)}</u></h3>
                    <p>Balance: <span><u>&#x20A6;{moneyFormatter(Math.ceil(loanBalance))}</u></span></p>
                    <div className="text-danger">{status===LOANSTATUS.ACTIVE?'Collection date:':'Application date:'}</div>
                    <div>{new Date(LOANSTATUS.ACTIVE?startDate:applicationDate).toLocaleDateString()}</div>
                </div>
                <div>
                    {
                        [
                            LOANSTATUS.ACTIVE,
                            LOANSTATUS.BAD,
                            LOANSTATUS.DOUBTFUL,
                            LOANSTATUS.NON_PERFORMING,
                        ].includes(loan.status) &&
                        <div className="mt-2 mb-1">
                            <Button 
                                onClick={()=>{
                                    // open repayment modal
                                    setRepaymentModal(true)
                                }}
                                title="Instalments repayment" 
                                className="text-white btn-sm btn-success icon-btn" >
                                <FontAwesomeIcon className="me-2" icon={faCreditCard} /> 
                            </Button>
                        </div>
                    }
                </div>
                {openRepaymentModal && <LoanRepaymentModal show={openRepaymentModal} onHide={()=>setRepaymentModal(false)} loan={loan} />}
            </div>
            </>} isEdit={false}  />
        </div>
    )
}

