
// email validation test
export const emailTester = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

// firebase storage test
export const fbsession = /^firebase:authUser:/

// domain tester
export const adminDomainTester = /@kuramocooperative.com\s*$/

// domain tester
export const superDomainTester = /@kiscms.com\s*$/

// phone number validation
export const phoneNumberTester = /^(\+234|234|0)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907)([0-9]{7})$/

// password validation
export const passwordTester = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
