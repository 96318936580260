import { Dispatch } from "react"
import { PageReducerAction, REDUCER_TYPES } from "../providers/PageProvider"

/**
 * Toggle page spinner
 * @param dispatch 
 * @param isBusy 
 */
export const setPageIsBusy = (dispatch: Dispatch<PageReducerAction>, isBusy: boolean)=>{
    dispatch({type: REDUCER_TYPES.PAGE_IS_BUSY,  pageIsBusy: isBusy} as PageReducerAction)
}

/**
 * Change screen titles and subtitles
 * @param dispatch 
 * @param title 
 * @param subTittle 
 */
export const setPageTitle = (dispatch: Dispatch<PageReducerAction>, title: string, subTittle: string)=>{
    dispatch({type: REDUCER_TYPES.SET_PAGE_TITLE, pageTitle: title, pageSubtitle: subTittle} as PageReducerAction)
}