import { MouseEvent, ReactNode } from "react"
import DataTable, { createTheme, TableColumn } from "react-data-table-component"
import { ConditionalStyles, PaginationChangePage, PaginationChangeRowsPerPage, TableStyles } from "react-data-table-component/dist/src/DataTable/types"
import { moneyFormatter } from "../utility/helpers"
import { LoanScheduleColumnData, FinanceData, Loan } from "../dataTypes/financials"

 export const CustomTable = (
    {
        columns, 
        data, 
        rowKey, 
        title, 
        isSelectable = true, 
        numRows = 50, 
        total,
        onClick,
        onPaginate,
        onChangeNumRows,
        theme,
        conditionalRowStyles,
        tableStyle,
        isLoading,
        actions
    }:
    {
        columns: TableColumn<any>[], 
        data: unknown[], 
        rowKey: string, 
        title?: string, 
        isSelectable?: boolean, 
        numRows?: number, 
        total?: number,
        onClick?: (row: any, event?: MouseEvent)=>void,
        onPaginate?: PaginationChangePage,
        onChangeNumRows?: PaginationChangeRowsPerPage,
        theme?: TABLE_THEMES,
        conditionalRowStyles?: ConditionalStyles<unknown>[],
        tableStyle?: TableStyles,
        isLoading?: boolean
        actions?: ReactNode | ReactNode[]
    })=>{
    return (
        <DataTable 
        columns={columns}
        data={data}
        keyField={rowKey}
        title={title}
        selectableRows = {isSelectable}
        striped
        onRowClicked={onClick}
        pagination
        paginationTotalRows={total}
        paginationPerPage={numRows}
        paginationServer
        onChangePage={onPaginate}
        onChangeRowsPerPage={onChangeNumRows}
        fixedHeader
        progressPending={isLoading}
        theme={theme}
        conditionalRowStyles={conditionalRowStyles}
        customStyles={tableStyle}
        actions={actions}
    />
    )
 }

enum TABLE_THEMES {
    DARK = 'dark',
    SALARIZED = 'solarized',
    DEFAULT = 'default'
}

createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      default: '#002b36',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');


  export const financialcolumns: TableColumn<FinanceData>[] = [
      {
          name: 'Date',
          selector: row=>new Date(row.date).toDateString(),
          sortable: true,
          // center: true,
      },
      {
        name: 'Member name',
        selector: row=>row.memberName,
        // center: true,
      },
      // {
      //     name: 'Transaction ref',
      //     selector: row=>row.transactionRef,
      //     // center: true,
      // },
      {
          name: 'Amount',
          selector: row=>moneyFormatter(row.amount),
          sortable: true,
      },
      {
        name: 'Purpose',
        selector: row=>row.description,
        sortable: true,
    },
  ]

  export const loanScheduleColumns: TableColumn<LoanScheduleColumnData>[] = [
    
    {
      name: 'Member name',
      selector: row=>row.name,
      // center: true,
    },
    {
        name: 'Collection date',
        selector: row=>new Date(row.collectionDate).toDateString(),
        // center: true,
    },
    {
        name: 'Capital',
        selector: row=>moneyFormatter(row.capital),
        sortable: true,
    },
    {
      name: 'Interest',
      selector: row=>moneyFormatter(row.interest),
      sortable: true,
    },
    {
      name: 'Earned Income',
      selector: row=>moneyFormatter(row.earned),
      sortable: true,
    },
    {
      name: 'Unearned Income',
      selector: row=>moneyFormatter(row.unearned),
      sortable: true,
    },
    {
      name: 'Status',
      selector: row=>row.status,
      sortable: true,
    },
    
  ]

  export const loanRepaymentsColumn: TableColumn<FinanceData>[] = [
    {
      name: 'Date',
      selector: row=>new Date(row.date).toDateString(),
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row=>row.amount,
      // sortable: true,
    },
    {
      name: 'Source',
      selector: row=>row.source,
      sortable: true,
    },
    {
      name: 'Collector',
      selector: row=>row.processor,
      sortable: true,
    },
  ]

  export const loanGuaranteeColumn = (guarantorId: string): TableColumn<Loan>[] => {
    return (
      [

        {
          name: 'Collection Date',
          selector: row=>new Date(row.collectionDate).toDateString(),
          sortable: true,
        },
        {
          name: 'Applicant',
          selector: row=>row.applicantName,
          sortable: true,
        },
        {
          name: 'Category',
          selector: row=>row.category,
          sortable: true,
        },
        {
          name: 'Loan Balance',
          selector: row=>moneyFormatter((row.capital * (1+(row.interestRate/100)))-(
            row.repayments.length > 0?(
              row.repayments.reduce((prev, curr)=>({...curr, amount: +curr.amount+prev.amount})).amount
            ):0
          )),
          sortable: true,
        },
    
        {
          name: 'Guarantor Liability',
          selector: row=>moneyFormatter(row.guarantors?(
            (row.guarantors.find(gur=>gur.reference===guarantorId)?.sumRequest??0)-
            (row.guarantors.find(gur=>gur.reference===guarantorId)?.requestOffset??0)
          ):0),
          // sortable: true,
        },
        
        {
          name: 'Status',
          selector: row=>row.status,
          sortable: true,
        },
      ]
    )
  }

  export const loanGuaranteeRequestColumn = (guarantorId: string): TableColumn<Loan>[] => {

    return (
      [
        {
          name: 'Request Date',
          selector: row=>new Date(row.applicationDate).toDateString(),
          sortable: true,
        },
        {
          name: 'Applicant',
          selector: row=>row.applicantName,
          sortable: true,
        },
        {
          name: 'Category',
          selector: row=>row.category,
          sortable: true,
        },
        {
          name: 'Loan Amount',
          selector: row=>moneyFormatter(row.capital, 2),
          sortable: true,
        },
        {
          name: 'Duration',
          selector: row=>`${row.instalments} months`,
          sortable: true,
        },
        {
          name: 'Request',
          selector: row=>moneyFormatter(row.guarantors.find(gurantor=>gurantor.reference===guarantorId)?.sumRequest??0, 2),
          sortable: true,
        },        
      ]
    )
  }

        
