import { Dispatch, SetStateAction, useState } from "react"
import { Alert, Button, FormLabel, Modal } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle, faSave } from "@fortawesome/free-solid-svg-icons"
// import { Loan } from "../dataTypes/financials"
import { moneyFormatter } from "../utility/helpers"
// import { GuarantorLabel } from "./GuarnatorLabel.component"
import { LoanApplicationData, LoanApplicationReducerAction } from "../reducers/LoanApplication.reducer"
import { GuarantorLabel } from "./GuarnatorLabel.component"
import { computeUnguaranteedSum, saveLoanForm } from "../methods/loanGuarantee"

export const LoanGuarantorFormModal = ({
    loanData, 
    setData,
    show,
    setShow
}: {
    loanData: LoanApplicationData,
    setData: Dispatch<LoanApplicationReducerAction>,
    show: boolean,
    setShow: Dispatch<SetStateAction<boolean>>
})=>{
    const { guaranteeRequest, capital, instalments, interestRate, guarantors } = loanData.loanApplicationForm!
    const [labelIndex, setLabelIndex] = useState<number[]>([0])
    const [error, setError] = useState<string | null>(null)

    const unguaranteedSum = computeUnguaranteedSum(guarantors, guaranteeRequest)

    return (
        <Modal
          centered
          show={show}
          onHide={()=>setShow(false)}
        >
          <Modal.Header closeButton >
            <Modal.Title>
              Loan Guarantors
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
            <div className="row">
                <div className="col-4">
                    <FormLabel>Capital</FormLabel>
                </div>
                <div className="col">&#x20A6;{moneyFormatter(capital, 2)}</div>
            </div>
            <div className="row">
                <div className="col-4">
                    <FormLabel>Interest</FormLabel>
                </div>
                <div className="col">&#x20A6;{moneyFormatter(capital * (interestRate/100), 2)}</div>
            </div>
            <div className="row">
                <div className="col-4">
                    <FormLabel>Duration</FormLabel>
                </div>
                <div className="col">{instalments} Months </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <FormLabel>Monthly Instalments</FormLabel>
                </div>
                <div className="col">&#x20A6;{moneyFormatter((
                    (capital * (1 + (interestRate/100)))/instalments
                    ), 2)}</div>
            </div>
            <div className="text-center mt-2">
                <h6 className="me-2 text-danger">Unguaranteed Sum:</h6>
                <div className="display-3">
                    &#x20A6;{moneyFormatter(unguaranteedSum, 2)}
                </div>
            </div>

               {  error && <Alert  variant="danger" >
                    {error}
                </Alert>}
            
            {
                <div className="ps-2 mb-1">
                    <Button onClick={()=>{
                        setLabelIndex(prev=>[...prev, prev.length])
                    }} variant="warning" className="btn-sm">
                        Add Guarantor <FontAwesomeIcon className="ms-2" icon={faPlusCircle} /> 
                    </Button>
                </div>
            }
            <div>
                {
                  labelIndex.map(indx=><GuarantorLabel 
                        applicationData={loanData}
                        formDispatch={setData}
                        key={`${indx}`}
                        setError={setError}
                    />)  
                }
            </div>
        </div>
          </Modal.Body>
          <Modal.Footer>
            <Button 
                className="btn-success btn-sm"
                disabled={unguaranteedSum > 0}
                onClick={async ()=>{
                    // save loan form
                    await saveLoanForm(loanData.loanApplicationForm!)
                    // close modal
                    setShow(false)
                }}
                >
                <FontAwesomeIcon className="me-2" icon={faSave} />
                Submit
            </Button>
          </Modal.Footer>
          </Modal>
        
    )
}