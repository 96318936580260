import { firestorDB } from "../configurations/firebase.config";
import { BaseModel } from "firebaseql";



export class Shares extends BaseModel {

    firestorDB = firestorDB
    table = 'shares'

}