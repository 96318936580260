import { useContext, useState } from "react"
import { Outlet } from "react-router-dom"

// styles sheet and popper methods
import '../../styles/custom.css'
import '../../styles/main.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.min'

// Components
import { Header } from "../../components/Header.component"
import { Sidebar } from "../../components/Sidebar.component"
import { Footer } from "../../components/Footer.component"
import PageTitleComponent from "../../components/PageTitle.component"
import { useIdleTimer } from "react-idle-timer"
import { PageDispacthContext } from "../../providers/PageProvider"
import { signOut } from "../../methods/auth"
import { toast } from "react-toastify"

export const PageWrapper = ()=>{
    const [toggle, setToggle] = useState<boolean>(true)
    const setCurrentData = useContext(PageDispacthContext)

    const onIdle = () => {
        toast("End of session!", {type: "info"})
        signOut(setCurrentData)
      }

    useIdleTimer({
        onIdle,
        timeout: 900_000,
        throttle: 500
    })

    

    return (
        <section className={toggle?"":"toggle-sidebar"}>
            <Header setToggle={setToggle} /> 
            <Sidebar />
            <main id="main" className="main">
                <PageTitleComponent />
                <Outlet />
            </main>
            <Footer />
        </section>
    )
}