import { memo, useContext } from "react";
import { Alert } from "react-bootstrap";
import { PageContext } from "../providers/PageProvider";




 const Pagetitle = () => {
    const { pageTitle,  pageSubtitle,  message } = useContext(PageContext)

    return (
        <div className="pagetitle">
        <h1 className="text-success">{pageTitle}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">{pageSubtitle}</li>
          </ol>
        </nav>

        <div className="text-center">
            {message && <Alert variant={message.type} >{message.message}</Alert>}
        </div>
      </div>
    );
}

export default memo(Pagetitle)