import { useContext } from "react"
import { Link } from "react-router-dom"
import { PageContext, PageDispacthContext } from "../providers/PageProvider"
import { signOut } from "../methods/auth"
import { setPageTitle } from "../methods/utility"

export const Profile = ()=>{
    const {currentUser} = useContext(PageContext)!
    const setCurrentData = useContext(PageDispacthContext)

    return (
        <li className="nav-item dropdown pe-3">

        <span className="nav-link nav-profile d-flex align-items-center pe-0"  data-bs-toggle="dropdown">
            <img src={currentUser?.photoUrl} alt="Profile" className="rounded-circle" />
        </span>

        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
            
            <li>
                <hr className="dropdown-divider" />
            </li>

            <li onClick={()=>{
                setPageTitle(setCurrentData, "User profile", "Membershp Information")
            }}>
                <Link to="/profile" className="dropdown-item d-flex align-items-center">
                    <i className="bi bi-person"></i>
                    <span>My Profile</span>
                </Link>
            </li>
           
            <li>
                <hr className="dropdown-divider" />
            </li>

            <li>
                <Link to="/complaints" className="dropdown-item d-flex align-items-center" >
                    <i className="bi bi-question-circle"></i>
                    <span>Need Help?</span>
                </Link>
            </li>
            <li>
                <hr className="dropdown-divider" />
            </li>

            <li className="dropdown-item" onClick={()=>{
                signOut(setCurrentData)
            }}>
                <i className="bi bi-box-arrow-right"></i>
                <span>Sign Out</span>
            </li>

        </ul>
        </li>
    )
}