import { usePaystackPayment } from "react-paystack";
import { PaystackProps } from "react-paystack/dist/types";

const PAYSTACK_TEST_KEY = 'pk_test_636d906302e7382afb1a5c257cb6ebedabeae6dd';

export const useInitializePayment = (email: string, amount:number, reference?: string)=>{
    const config: PaystackProps = {
        email: email,
        amount: amount, 
        publicKey: PAYSTACK_TEST_KEY,
        reference
    };
    return usePaystackPayment(config);
}