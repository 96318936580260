import { Dispatch, SetStateAction } from "react"
import { Link } from "react-router-dom"
import { Profile } from "./Profile.component"

export const Header = ({setToggle} : {setToggle: Dispatch<SetStateAction<boolean>>})=>{
    return (
        <header id="header" className="header fixed-top d-flex align-items-center primary-background">

            <div className="d-flex align-items-center justify-content-between">
                <Link to="/" className="logo d-flex align-items-center">
                    <img className="header-logo" src="./logo.png" alt="" />
                    <span className="d-none d-lg-block text-success">KISCMS</span>
                </Link>
                <i className="bi bi-list toggle-sidebar-btn text-dark" onClick={
                    () => {
                        setToggle(prev=>!prev)
                    }
                }></i>
            </div>

            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">
                    <Profile />
                </ul>
            </nav>

        </header>
    )
}