import 'bootstrap/dist/css/bootstrap.min.css'
import {  PageProvider } from './providers/PageProvider';



function App() {

  return (
    <PageProvider />
  );
}

export default App;
