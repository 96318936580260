import { Modal, Spinner, ModalBody } from "react-bootstrap"

export const CustomSpinner = ()=>{
    return (
        <Modal
            show={true}
            // onHide={()=>{}}
            dialogAs="section"
            className="d-flex justify-content-center align-items-center"
            >
            <ModalBody>
                <div className="text-center">
                    <div >
                        <Spinner animation="grow" variant="success" />
                        <Spinner animation="grow" variant="danger" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="info" />
                    </div>
                    <div className="text-warning">
                        ...please wait
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}