import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCreditCard } from "@fortawesome/free-solid-svg-icons"
import { Dispatch, useState } from "react"
import { moneyFormatter } from "../utility/helpers"
import { DBMODELS } from "../dataTypes/UTILITY.constants"
import { BalanceModal } from "./BalanceModal.component"
import { LandingPageData, LandingPageReducerAction } from "../reducers/LandingPage.reducer"
import { ENVIRONMENT, environment } from "../configurations/firebase.config"
import { toast } from "react-toastify"


export const CrediBalance = ({amount, title, isCalculated, pageSetter, previousData }:
    {amount:number, title: DBMODELS, isCalculated?: boolean, pageSetter?: Dispatch<LandingPageReducerAction>, previousData?: LandingPageData })=>{

    const [showModal, setShowModal] = useState<boolean>(false)

    return (
        <div>
            <h1 className="text-success text-center">&#8358;{moneyFormatter(amount)}</h1>
            {
                !isCalculated &&
                <div onClick={()=>{
                    if(environment===ENVIRONMENT.PRODUCTION){
                        toast("Feature is currently disabled!", {type: 'info'})
                    } else {
                        setShowModal(true)
                    }
                }
                } className="moneyCard btn card" title={`make payment for ${DBMODELS[title]}`} >
                    <FontAwesomeIcon icon={faCreditCard} />
                </div>
            }
            {
                (showModal && environment===ENVIRONMENT.DEVELOPMENT) &&
                <BalanceModal 
                    show={showModal} 
                    onHide={()=>setShowModal(false)}
                    title={title}
                    pageSetter={pageSetter!}
                    previousData={previousData!}
                />
            }
        </div>
    )
}