import { faSave, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCallback, useContext, useEffect, useReducer, useState } from "react"
import { Button, ButtonGroup, Card, FormGroup, FormLabel } from "react-bootstrap"
import { LoanGuarantorFormModal } from "../../components/LoanGuarantorModal.component"
import { 
    LOANAPPLICTION_DATA_TYPES, 
    loanApplicationInitialData, 
    loanApplicationReducer, 
    setLoanApplicationFormData, 
    updateLoanFormData
} from "../../reducers/LoanApplication.reducer"
import { PageContext, PageDispacthContext, setGlobalMessage } from "../../providers/PageProvider"
import { useLocation, useNavigate } from "react-router-dom"
import { Balances, Loan } from "../../dataTypes/financials"
import { saveLoanForm } from "../../methods/loanGuarantee"
import { toast } from "react-toastify"
import { setPageTitle } from "../../methods/utility"


export const LoanApplication = ()=>{
    const location = useLocation()
    const navigate = useNavigate()
    const [showGuarnator, setShowGuarantor] = useState<boolean>(false)
    const [pageData, dispatch] = useReducer(loanApplicationReducer, loanApplicationInitialData)
    const {currentUser, message} = useContext(PageContext)
    const pageDispatch = useContext(PageDispacthContext)
    const [monthlySavings, setMonthlySavings] = useState<number>(currentUser?.KISCMSInfo.monthlySavings!)

    // fetch initial page data
    const fetchInitialData = useCallback(async (existingLoans: Loan[], guaranteedLoans: Loan[], balances: Balances)=>{
        const data = await setLoanApplicationFormData(currentUser!, existingLoans, guaranteedLoans, balances, pageDispatch)
        dispatch({type: LOANAPPLICTION_DATA_TYPES.SET_INITIAL_DATA, ...data})
    }, [currentUser, pageDispatch])

    useEffect(()=>{
        if(!location.state){
            // return to previous page
            toast("Please navigate via dashboard!", {type: 'warning'})
            setPageTitle(pageDispatch, "Financial Overview", "Data Summary")
            navigate("/")
        } else {
            const {existingLoans, guaranteedLoans, balances} = location.state
            fetchInitialData(existingLoans, guaranteedLoans, balances)
        }
    }, [fetchInitialData, location.state, pageDispatch, navigate])

    const {loanApplicationForm, loanConfigurations, currentLoanCategory } = pageData
    
    return (
       <div className="d-flex justify-content-center">
             <Card className="w-75">
                <Card.Header>Loan Application Form</Card.Header>
                <Card.Body>
                <div className="mt-2" >
                    <FormGroup className="mb-2">
                        <FormLabel>
                            Loan category:
                        </FormLabel>
                        <select title="Loan category" className="form-control" 
                              defaultValue={loanApplicationForm?.category} onChange={(e)=>{
                                updateLoanFormData(pageData, dispatch, pageDispatch, monthlySavings, currentUser!, e)
                              }}  
                            name="category"> 
                            <option value="default">Select loan category</option>
                            {
                                loanConfigurations.length > 0 && loanConfigurations.map((locanCategory, indx)=>{
                                  return <option key={indx} value={JSON.stringify(locanCategory)}>{locanCategory.title}</option>
                                })
                            }
                        </select>
                    </FormGroup>

                    { currentLoanCategory && 
                        <FormGroup className="mb-2">
                            <FormLabel>
                                Loan amount:
                            </FormLabel>
                            <input title="Laon amount" type="number" min={100} className="form-control" 
                                onChange={(e)=>{
                                    updateLoanFormData(pageData, dispatch, pageDispatch, monthlySavings, currentUser!, e)
                                }}
                                defaultValue={loanApplicationForm?.capital} 
                                name="capital" 
                                />
                        </FormGroup> 
                      }

                    {
                        currentLoanCategory &&
                        <FormGroup className="mb-2">
                        <FormLabel>
                            Loan duration:
                        </FormLabel>
                        <select title="Laon duration" className="form-control" 
                           defaultValue={loanApplicationForm?.instalments} onChange={(e)=>{
                                updateLoanFormData(pageData, dispatch, pageDispatch, monthlySavings, currentUser!, e)
                             }}  
                            name="instalments">
                            <option value="default">Select duration</option>
                            {
                                currentLoanCategory.plans?.map((loanPlan, indx)=>{
                                  return <option key={indx} value={JSON.stringify(loanPlan)}>{loanPlan.instalments}</option>
                                })
                            }
                        </select>
                    </FormGroup>
                    }              

                        <FormGroup className="mb-2">
                            <FormLabel>
                                Monthly savings:
                            </FormLabel>
                            <input title="Loan amount" type="number" min={100} className="form-control" 
                                onChange={(e)=>{
                                    if(isNaN(parseInt(e.target.value))){
                                        setGlobalMessage(pageDispatch, "Please enter a valid amount as savings", true)
                                    } else {
                                        setGlobalMessage(pageDispatch, null)
                                        setMonthlySavings(parseInt(e.target.value))
                                        updateLoanFormData(pageData, dispatch, pageDispatch, parseInt(e.target.value) , currentUser!, e)
                                    }
                                }} 
                                defaultValue={monthlySavings} 
                                name="savings" />
                        </FormGroup>
                        
                    </div>
                </Card.Body>
                <Card.Footer>
                    <ButtonGroup>
                        {
                            pageData.requireGuarantee?
                            <Button 
                                disabled={message!==null || pageData.canSubmit===false}
                                onClick={()=>setShowGuarantor(true)} className="btn-sm btn-warning">
                                <FontAwesomeIcon className="me-2" icon={faSearch} />
                                Find Guarantor
                            </Button>:
                            <Button 
                                disabled={message!==null || pageData.canSubmit===false}
                                onClick={ async ()=>{
                                    await saveLoanForm(pageData.loanApplicationForm!)
                                    navigate("/")
                                }}  
                                className="btn-sm btn-success">
                                <FontAwesomeIcon className="me-2" icon={faSave} />
                                Apply
                            </Button>
                        }
                    </ButtonGroup>
                </Card.Footer>
            </Card>
            {showGuarnator && 
            <LoanGuarantorFormModal 
                show={showGuarnator} 
                setData={dispatch}
                loanData={pageData}
                setShow={setShowGuarantor}
            />}
       </div>
    )
}