import { ChangeEvent, Dispatch } from "react"
import { LOANSTATUS } from "../dataTypes/UTILITY.constants"
import { Balances, LOAN_BASIS, Loan, LoanConfiguration, LoanPlans, OtherConfigurationItem } from "../dataTypes/financials"
import { LoanGuarantorData, UserData } from "../dataTypes/user.types"
import { LoanConfigurations } from "../models/LoanConfigurations"
import { OtherConfigurations } from "../models/OtherConfigurations"
import { PageReducerAction, setGlobalMessage } from "../providers/PageProvider"
import { setPageIsBusy } from "../methods/utility"
import { moneyFormatter } from "../utility/helpers"


export type LoanApplicationData = {
    loanConfigurations: LoanConfiguration[],
    otherConfigurations: OtherConfigurationItem,
    loanApplicationForm: Loan | null,   
    currentLoanCategory: LoanConfiguration | null,
    existingLoans: Loan[],
    guaranteedLoans: Loan[],
    requireGuarantee: boolean,
    balances?: Balances,
    canSubmit: boolean
}

export type LoanApplicationReducerAction = {
    type: LOANAPPLICTION_DATA_TYPES,
    loanConfigurations: LoanConfiguration[],
    otherConfigurations: OtherConfigurationItem,
    loanApplicationForm: Loan | null,
    currentLoanCategory: LoanConfiguration | null,
    existingLoans: Loan[],
    guaranteedLoans: Loan[],
    requireGuarantee: boolean,
    balances?: Balances,
    canSubmit: boolean
}

export enum LOANAPPLICTION_DATA_TYPES {
    SET_CURRENT_LOAN_CATEGORY,
    SET_INITIAL_DATA,
    SET_FORM_DATA,
    SET_REQUIRE_GUARANTEE,
    SET_CAN_SUBMIT
}


export const loanApplicationInitialData: LoanApplicationData = {
   loanConfigurations: [],
   otherConfigurations: {insuranceCost: 0} as OtherConfigurationItem,
   loanApplicationForm: null,
   currentLoanCategory: null,
   existingLoans: [],
   guaranteedLoans: [],
   requireGuarantee: false,
   canSubmit: false
}



export const loanApplicationReducer = (state: LoanApplicationData, action: LoanApplicationReducerAction): LoanApplicationData => {

    switch (action.type) {
        case LOANAPPLICTION_DATA_TYPES.SET_INITIAL_DATA:
            return {
                ...state,
                loanConfigurations: action.loanConfigurations,
                otherConfigurations: action.otherConfigurations,
                loanApplicationForm: action.loanApplicationForm,
                currentLoanCategory: action.currentLoanCategory,
                existingLoans: action.existingLoans,
                guaranteedLoans: action.guaranteedLoans,
                balances: action.balances
            };

        case LOANAPPLICTION_DATA_TYPES.SET_CURRENT_LOAN_CATEGORY:
                return {...state, currentLoanCategory: action.currentLoanCategory};
            
        case LOANAPPLICTION_DATA_TYPES.SET_FORM_DATA:
            return {...state, loanApplicationForm: {...action.loanApplicationForm} as Loan};
        
        case LOANAPPLICTION_DATA_TYPES.SET_REQUIRE_GUARANTEE:
            return {...state, requireGuarantee: action.requireGuarantee}
        
        case LOANAPPLICTION_DATA_TYPES.SET_CAN_SUBMIT:
            return {...state, canSubmit: action.canSubmit}

        default:
            return state;
    }
}

/**
 * setup initial loan form data
 * @param currentUser 
 * @returns 
 */
const setInitialLoanForm = (currentUser: UserData): Loan => {
    const {firstName, lastName, reference} = currentUser
    const d = new Date()
    return {
        applicantName: `${firstName} ${lastName}`,
        category: '',
        applicantRef: reference!,
        capital: 0,
        instalments: 0,
        collectionDate: d.getTime(),
        applicationDate: d.getTime(),
        interestRate: 0,
        guaranteeRequest: 0,
        guarantorList: [],
        guarantors: [],
        startDate: d.getTime(),
        expectedEndDate: d.getTime(),
        createdBy: {
            name: `${firstName} ${lastName}`,
            date: d.getTime(),
            reference: reference!
        },
        day: d.getDay(),
        month: d.getMonth() + 1,
        year: d.getFullYear(),
        status: LOANSTATUS.UNGUARANTEED,
        repayments: [],
        approvals: new Map(),
        fines: []
    } 
}

/**
 * fetch all available loan configurations
 * @returns 
 */
const fetchLoanConfigurations = async (): Promise<LoanConfiguration[]>=>{
    const model = new LoanConfigurations()
    try {
        const loans = await model.findWhere({
            wh: [
                {
                    key: 'isAvailable',
                    value: true,
                    operator: '=='
                }
            ]
        })
        return loans as LoanConfiguration[]
    } catch (_) {
        return []
    }

}

/**
 * fetch insurance cost for loan
 * @returns 
 */
const fetchOtherConfiguration = async (): Promise<OtherConfigurationItem> => {
    const model = new OtherConfigurations()

    try {
        const configItem =  await model.findAll()
        if(configItem.length > 0){
            return configItem[0] as OtherConfigurationItem
        }else {
            return {insuranceCost: 0} as OtherConfigurationItem
        }
    } catch (_) {
        return {insuranceCost: 0} as OtherConfigurationItem
    }
}

/**
 * initial data for loan application page
 * @param currentUser 
 * @param dispatch 
 * @returns 
 */
export const setLoanApplicationFormData = async (currentUser: UserData, existingLoans: Loan[], guaranteedLoans: Loan[], balances: Balances, dispatch: Dispatch<PageReducerAction>): Promise<LoanApplicationData> => {
    setPageIsBusy(dispatch, true)
    const loanApplicationForm: Loan = setInitialLoanForm(currentUser)
    const [otherConfigurations, loanConfigurations] = await Promise.all([
        await fetchOtherConfiguration(),
        await fetchLoanConfigurations()
    ])

    setPageIsBusy(dispatch, false)

    return {
        loanApplicationForm,
        otherConfigurations,
        loanConfigurations,
        currentLoanCategory: null,
        // guarantors: [],
        existingLoans,
        guaranteedLoans,
        balances,
        requireGuarantee: false,
        canSubmit: false
    }
    
}


export const updateLoanFormData = (
    previousData: LoanApplicationData,
    dispatch: Dispatch<LoanApplicationReducerAction>,
    globalDispactch: Dispatch<PageReducerAction>,
    currentSavings: number,
    currentUser: UserData,
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
)=>{
    const key = event?.target.name
    const value = event?.target.value
    let loanExists = false
    
    // check previous loans
    if(key==='category'){
        const result = JSON.parse(value) as LoanConfiguration
        loanExists = checkLoanPreviouslyExists(previousData.existingLoans, result.title)
    }

    if(loanExists){
        // send message to user
        setGlobalMessage(globalDispactch, "You currently have an unliquidated loan in this category", true)
    } else {
        setGlobalMessage(globalDispactch, null)
        // capital
        // category
        // duration and interest
        const updateError = key!=='savings' ? setLoanFormData(previousData.loanApplicationForm!, dispatch, key as any, value!) : null
        if(updateError!==null){
            setGlobalMessage(globalDispactch, updateError, true)
        } else {
            // check minimum savings requirement
            const minimuSavingsError = checkMinimumSavings(currentSavings, currentUser.employmentDetail.currentSalary)
            if(minimuSavingsError!==null){
                setGlobalMessage(globalDispactch, minimuSavingsError, true)
            } else {
                const {instalments, interestRate, capital} = previousData.loanApplicationForm!
                if(instalments && interestRate){
                    // check has capacity
                    const capacity = getUserCapacity(
                        currentUser,
                        previousData
                    )
                    // check has ability
                    const instalmentAbility = getUserAbility(currentSavings, previousData.existingLoans, currentUser.employmentDetail.currentSalary)
                    const maxAbility = (instalmentAbility * instalments)/(1 + (interestRate/100))
                    const allowedLoan = maxAbility > capacity ? capacity: maxAbility
                    // check if loan applied for is not above capacity
                    if(allowedLoan < capital){
                        setGlobalMessage(globalDispactch, `Your maximum loan capacity is N${moneyFormatter(allowedLoan)}`, true)
                    } else {
                        dispatch({type: LOANAPPLICTION_DATA_TYPES.SET_CAN_SUBMIT, canSubmit: checkFormCanSubmit(previousData.loanApplicationForm!) } as LoanApplicationReducerAction)
                    }
                 
                    const capInt = (capital * (1+(interestRate/100)))

                    // check require guarantee
                    const base = getLoanBaseAmount(currentUser, previousData.balances!, previousData.currentLoanCategory?.basis!)
                    dispatch({type: LOANAPPLICTION_DATA_TYPES.SET_REQUIRE_GUARANTEE, requireGuarantee: (capInt > base) } as LoanApplicationReducerAction)

                    // set loan guarantee request amount
                    dispatch({type: LOANAPPLICTION_DATA_TYPES.SET_FORM_DATA, loanApplicationForm: {
                        ...previousData.loanApplicationForm,
                        guaranteeRequest: (capInt > base) ? (capInt - base) : 0,
                        status: (capInt > base) ? LOANSTATUS.UNGUARANTEED : LOANSTATUS.GUARANTEED
                    }} as LoanApplicationReducerAction)
                }
            }
        }
    }
}

/**
 * check if form can be submitted
 * @param formData 
 * @returns 
 */
const checkFormCanSubmit = (formData: Loan): boolean => {
    const checkers = ['capital', 'instalments', 'interestRate']
    let counter = 0
    const data = new Map(Object.entries(formData))
    checkers.forEach(checker=>{
        if(data.get(checker) as number > 0){
            counter++
        }
    })
    return checkers.length === counter
}



/**
 * Check if savings is up to required minimum
 * @param currentSavings 
 * @param currentSalary 
 * @returns 
 */
const checkMinimumSavings = (currentSavings: number, currentSalary: number): string | null =>{
    if(currentSavings >= 0.035 * currentSalary){
        return null
    } else {
        return `Your monthly savings cannot be less than N${moneyFormatter((currentSalary * 0.035), 2)}`
    }
}

/**
 * check member has unliquidated loan in category
 * @param previousLoans 
 * @param currentLoan 
 * @returns 
 */
const checkLoanPreviouslyExists = (previousLoans: Loan[], currentLoan: String): boolean => {
    const exists = previousLoans.find(loan=>loan.category===currentLoan)
    return exists!==undefined
}

/**
 * measure the maximum loan user can access
 * based on law
 * @param requiredBasis 
 * @param currentUser 
 * @param multiplier 
 * @param balances 
 * @returns 
 */
const getUserCapacity = (
    currentUser: UserData,
    currentData: LoanApplicationData
): number => {
    
    const {multiplier, basis} = currentData.currentLoanCategory!
    // capacity to borrow based on loan requirement
    // total savings, monthly balance, total shares
    return getLoanBaseAmount(currentUser, currentData.balances!, basis) * multiplier
}

const getLoanBaseAmount = (user: UserData, balances: Balances, basis: LOAN_BASIS): number => {
    switch (basis) {
        case LOAN_BASIS.MONTHLY_BALANCE:
            return ((user.employmentDetail.currentSalary * 0.9) - (user.employmentDetail.currentSalary/6)) // TODO: less loan repayments
        
        case LOAN_BASIS.SALARY:
            return user.employmentDetail.currentSalary 
        
        case LOAN_BASIS.SHARE_CAPITAL:
            return balances.shares.amount! 
        
        case LOAN_BASIS.MONTHLY_SAVINGS:
            return user.KISCMSInfo.monthlySavings 
        
        case LOAN_BASIS.SAVINGS:
            return balances.savings.amount!

        default:
            return 0;
    }
}

/**
 * get monthly amount member can afford
 * @param currentSavings 
 * @param existingLoans 
 * @param currentSalary 
 * @returns 
 */
const getUserAbility = (
    currentSavings: number,
    existingLoans: Loan[],
    currentSalary: number
): number=>{
    // ability to pay back based on residual income
    // residual income = (currentSavings + otherLoansDeductions + currentLoanInstalments) <= (salary * 0.9 + (salary/6))
    const monthlyRepayments = totalLoanRepayments(existingLoans) + currentSavings
    const residualSalary = (currentSalary * 0.9) - (currentSalary/6)
    return residualSalary - monthlyRepayments
}

const totalLoanRepayments = (existingLoans: Loan[]): number=> {
    let result = 0

    for(let loan of existingLoans){
        result += computeCurrentLoanInstalment(loan)
    }
    return result
}

/**
 * calculate current month instalment on Lon
 * @param loan 
 * @returns 
 */
export const computeCurrentLoanInstalment = (loan: Loan): number => {
    let result = 0
    const {capital , interestRate, instalments, repayments} = loan
    if(repayments.length > 0){
        const totalRepayments = repayments.reduce((previous, current)=>(
            {
                ...current,
                amount: +previous.amount + current.amount
            }
        )).amount
        const monthlyInstalments = (capital * (1+(interestRate/100)))/instalments
        const balance = (capital * (1 + (interestRate/100))) - totalRepayments
        result = balance > monthlyInstalments ? monthlyInstalments : balance
    }
    return result
}


export const setLoanFormData = (
    state: Loan, 
    dispatch: Dispatch<LoanApplicationReducerAction>,
    key: 'capital' | 'instalments' | 'category'| 'guarantors', 
    value: string | number | LoanConfiguration | LoanGuarantorData): string | null =>
    {
    
    let updated = null
    
    if(key==='category'){
        // validate a true loan configuration submited
        if(value==='default'){
            updated = "Enter a valid loan category"
        } else {
            // updated = null
            const v = JSON.parse(value as string) as LoanConfiguration
            state.category = v.title
            dispatch({type: LOANAPPLICTION_DATA_TYPES.SET_FORM_DATA, loanApplicationForm: {...state}} as LoanApplicationReducerAction)
            dispatch({type: LOANAPPLICTION_DATA_TYPES.SET_CURRENT_LOAN_CATEGORY, currentLoanCategory: {...v}} as LoanApplicationReducerAction)
        }
    } else {
        if (key==='capital') {
            if(isNaN(parseInt(value as string))){
                updated = `Please enter a valid amount as loan ${key}`
            } else {
                state.capital = parseInt(value as string)
                dispatch({type: LOANAPPLICTION_DATA_TYPES.SET_FORM_DATA, loanApplicationForm: {...state }} as LoanApplicationReducerAction)
            }
        } else if(key==='instalments') {
            if(value==='default'){
                updated = `Please enter a valid amount as loan ${key}`
            } else {
                const plan = JSON.parse(value as string) as LoanPlans
                state.instalments = plan.instalments
                state.interestRate = plan.rate
                dispatch({type: LOANAPPLICTION_DATA_TYPES.SET_FORM_DATA, loanApplicationForm: {...state }} as LoanApplicationReducerAction)
            }
        }
        
    }
    return updated
    
}
