import { faContactBook } from "@fortawesome/free-regular-svg-icons"
import { faUser, faEdit, IconDefinition, faPeopleRoof, faBriefcase, 
            faLandmark, faDivide, faPiggyBank, faHandshakeAngle, 
            faPlugCircleCheck, faStar, faHeartCircleCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, ReactNode, useState } from "react"
import { Card } from "react-bootstrap"
import CardHeader from "react-bootstrap/esm/CardHeader"
import { FormModal } from "./FormModal.component"

export const EditableCard = ({
    cardBody, 
    pageTitle, 
    modalForm, 
    isEdit=true,
    isCurrent = false,
    titleBadge
}:{
    cardBody: ReactNode, 
    pageTitle: string, 
    modalForm?: ReactNode, 
    isEdit?: boolean,
    isCurrent?: boolean,
    titleBadge?: ReactNode
})=>{
    const [showForm, setShowForm] = useState<boolean>(false)
    return (
        <Card className="bg-dark text-light">
            <CardHeader className={`${isCurrent?"bg-danger text-warning":"bg-success"} text-light d-flex justify-content-between`}>
                <div className="d-flex justify-content-between align-items-center">
                    <div><FontAwesomeIcon icon={getTitleIcon(pageTitle)} className="me-2"/>{pageTitle}</div>
                    {isEdit && <div onClick={()=>{
                        setShowForm(true)
                    }} className="btn"><FontAwesomeIcon icon={faEdit} className="me-2"/></div>}
                </div>
                {titleBadge}
            </CardHeader>
            <Card.Body className="pt-3">
               <Fragment>
                    {cardBody}
                   { showForm && <FormModal show={showForm} onHide={()=>{setShowForm(false)}} form={modalForm} title={pageTitle} />}
               </Fragment>
            </Card.Body>
        </Card>
    )
}

const getTitleIcon = (title: string): IconDefinition=>{
    let icon = faUser
    switch (title) {
        case 'Contact Info':
            icon = faContactBook
            break;

        case 'Next of kin':
            icon = faPeopleRoof
            break;

        case 'Employment Info':
            icon = faBriefcase
            break;

        case 'Cooperative Info':
            icon = faLandmark
            break;

        case 'Unclaimed Dividend':
            icon = faDivide
            break;

        case 'Savings':
            icon = faPiggyBank
            break;
        
        case 'Shares':
            icon = faHandshakeAngle
            break;

        case 'Electronic Loan':
            icon = faPlugCircleCheck
            break;
                
        case 'Special Loan':
            icon = faStar
            break;

        case 'Compassionate Loan':
            icon = faHeartCircleCheck
            break;
    }
    return icon
}